import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoadingSpinner } from "./components/layouts/LoadingSpinner";
import SupplierPage from "pages/supplier/SupplierPage";
import SupplierAddPage from "pages/supplier/SupplierAddPage";
import SupplierEditPage from "pages/supplier/SupplierEditPage";
import SaleTargetPage from "pages/sale-target/SaleTargetPage";
import SaleTargetAddPage from "pages/sale-target/SaleTargetAddPage";
import SaleTargetEditPage from "pages/sale-target/SaleTargetEditPage";
import AllTargetPage from "pages/sale-target/all-target/AllTargetPage";
import AllTargetAddPage from "pages/sale-target/all-target/AllTargetAddPage";
import AllTargetEditPage from "pages/sale-target/all-target/AllTargetEditPage";
import ProductTargetPage from "pages/sale-target/product-target/ProductTargetPage";
import ProductTargetAddPage from "pages/sale-target/product-target/ProductTargetAddPage";
import ProductTargetEditPage from "pages/sale-target/product-target/ProductTargetEditPage";
import RequireAuth from "pages/RequireAuth";
import OrderApprovalEditPage from "./pages/primary-sales-order/order-approval/OrderApprovalEditPage";
import ConsignmentReturnPage from "pages/consignment-return/ConsignmentReturnPage";
import ConsignmentReturnAddPage from "pages/consignment-return/ConsignmentReturnAddPage";
import ConsignmentReturnEditPage from "pages/consignment-return/ConsignmentReturnEditPage";
import ConsignmentPage from "pages/consignment/consignment/ConsignmentPage";
import ConsignmentAddPage from "pages/consignment/consignment/ConsignmentAddPage";
import ConsignmentEditPage from "pages/consignment/consignment/ConsignmentEditPage";
import ConsignmentInvoicePage from "pages/consignment/invoice/ConsignmentInvoicePage";
import ConsignmentInvoiceAddPage from "pages/consignment/invoice/ConsignmentInvoiceAddPage";
import ConsignmentInvoiceEditPage from "pages/consignment/invoice/ConsignmentInvoiceEditPage";
import ConsignmentPaymentReceivePage from "pages/consignment/payment-receive/ConsignmentPaymentReceivePage";
import ConsignmentPaymentReceiveAddPage from "pages/consignment/payment-receive/ConsignmentPaymentReceiveAddPage";
import GoodsDeliveryNoteEditPage from "pages/goods-request/delivery-note/GoodsDeliveryNoteEditPage";
import TripManagementAddPage from "pages/trip/trip-management/TripManagementAddPage";
import VehicleAddPage from "pages/trip/vehicle/VehicleAddPage";
import VehicleEditPage from "pages/trip/vehicle/VehicleEditPage";
import TripApprovalPage from "pages/trip/trip-approval/TripApprovalPage";
import TripApprovalEditPage from "pages/trip/trip-approval/TripApprovalEditPage";
import AccessDeniedPage from "pages/AccessDeniedPage";

const SalePromotionPlanPage = lazy(
  () => import("./pages/sale-promotion/plan/SalePromotionPlanPage")
);

const SalePromotionPlanAddPage = lazy(
  () => import("./pages/sale-promotion/plan/SalePromotionPlanAddPage")
);

const SalePromotionPlanEditPage = lazy(
  () => import("./pages/sale-promotion/plan/SalePromotionPlanEditPage")
);

const MarketingPlanPage = lazy(
  () => import("./pages/marketing-promotion/plan/MarketingPlanPage")
);

const MarketingPlanAddPage = lazy(
  () => import("./pages/marketing-promotion/plan/MarketingPlanAddPage")
);

const MarketingPlanEditPage = lazy(
  () => import("./pages/marketing-promotion/plan/MarketingPlanEditPage")
);

const MarketingInvoicePage = lazy(
  () => import("./pages/marketing-promotion/invoice/MarketingInvoicePage")
);

const MarketingInvoiceAddPage = lazy(
  () => import("./pages/marketing-promotion/invoice/MarketingInvoiceAddPage")
);

const MarketingInvoiceEditPage = lazy(
  () => import("./pages/marketing-promotion/invoice/MarketingInvoiceEditPage")
);

const MarketingPaymentReceivePage = lazy(
  () =>
    import(
      "./pages/marketing-promotion/payment-receive/MarketingPaymentReceivePage"
    )
);

const MarketingPaymentReceiveAddPage = lazy(
  () =>
    import(
      "./pages/marketing-promotion/payment-receive/MarketingPaymentReceiveAddPage"
    )
);

const MarketingGoodRequestPage = lazy(
  () =>
    import(
      "./pages/marketing-promotion/good-requisition/MarketingGoodRequestPage"
    )
);

const MarketingGoodRequestEditPage = lazy(
  () =>
    import(
      "./pages/marketing-promotion/good-requisition/MarketingGoodRequestEditPage"
    )
);

const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const OfficeUserPage = lazy(
  () => import("./pages/user-management/office-user/OfficeUserPage")
);
const OfficeUserAddPage = lazy(
  () => import("./pages/user-management/office-user/OfficeUserAddPage")
);
const OfficeUserEditPage = lazy(
  () => import("./pages/user-management/office-user/OfficeUserEditPage")
);
const ResetPasswordPage = lazy(
  () => import("./pages/user-management/office-user/ResetPasswordPage")
);

const VehiclePage = lazy(() => import("./pages/trip/vehicle/VehiclePage"));
const CustomerUserPage = lazy(
  () => import("./pages/user-management/customer-user/CustomerUserPage")
);
const CustomerCategoryPage = lazy(
  () => import("./pages/customer/customer-category/CustomerCategoryPage")
);
const CustomerCategoryAddPage = lazy(
  () => import("./pages/customer/customer-category/CustomerCategoryAddPage")
);
const CustomerCategoryEditPage = lazy(
  () => import("./pages/customer/customer-category/CustomerCategoryEditPage")
);
const CustomerPage = lazy(
  () => import("./pages/customer/customer/CustomerPage")
);
const CustomerAddPage = lazy(
  () => import("./pages/customer/customer/CustomerAddPage")
);
const CustomerEditPage = lazy(
  () => import("./pages/customer/customer/CustomerEditPage")
);
const ProductCategoryPage = lazy(
  () => import("./pages/product/product-category/ProductCategoryPage")
);
const ProductCategoryAddPage = lazy(
  () => import("./pages/product/product-category/ProductCategoryAddPage")
);
const ProductCategoryEditPage = lazy(
  () => import("./pages/product/product-category/ProductCategoryEditPage")
);
const ProductTagPage = lazy(
  () => import("./pages/product/product-tag/ProductTagPage")
);
const ProductTagAddPage = lazy(
  () => import("./pages/product/product-tag/ProductTagAddPage")
);
const ProductTagEditPage = lazy(
  () => import("./pages/product/product-tag/ProductTagEditPage")
);
const BrandPage = lazy(() => import("./pages/product/brand/BrandPage"));
const BrandAddPage = lazy(() => import("./pages/product/brand/BrandAddPage"));
const BrandEditPage = lazy(() => import("./pages/product/brand/BrandEditPage"));
const UnitOfMeasurementPage = lazy(
  () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementPage")
);
const UnitOfMeasurementAddPage = lazy(
  () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementAddPage")
);
const UnitOfMeasurementEditPage = lazy(
  () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementEditPage")
);
const UnitOfConversionPage = lazy(
  () => import("./pages/unit/unit-of-conversion/UnitOfCoversionPage")
);
const UnitOfConversionAddPage = lazy(
  () => import("./pages/unit/unit-of-conversion/UnitOfConversionAddPage")
);
const UnitOfConversionEditPage = lazy(
  () => import("./pages/unit/unit-of-conversion/UnitOfConversionEditPage")
);
const BusinessUnitPage = lazy(
  () => import("./pages/customer/business-unit/BusinessUnitPage")
);
const BusinessUnitAddPage = lazy(
  () => import("./pages/customer/business-unit/BusinessUnitAddPage")
);
const BusinessUnitEditPage = lazy(
  () => import("./pages/customer/business-unit/BusinessUnitEditPage")
);
const CustomerNamePage = lazy(
  () => import("./pages/user-management/customer-user/CustomerNamePage")
);
const CustomerUserAddPage = lazy(
  () => import("./pages/user-management/customer-user/CustomerUserAddPage")
);
const CustomerUserEditPage = lazy(
  () => import("./pages/user-management/customer-user/CustomerUserEditPage")
);
const ProductInformationPage = lazy(
  () => import("./pages/product/product-information/ProductInformationPage")
);
const ProductInformationAddPage = lazy(
  () => import("./pages/product/product-information/ProductInformationAddPage")
);
const ProductInformationEditPage = lazy(
  () => import("./pages/product/product-information/ProductInformationEditPage")
);
const WayManagementpage = lazy(
  () => import("./pages/distribution-region/way-management/WayManagementPage")
);
const WayManagmentAddPage = lazy(
  () => import("./pages/distribution-region/way-management/WayManagmentAddPage")
);
const WayManagementEditPage = lazy(
  () =>
    import("./pages/distribution-region/way-management/WayManagementEditPage")
);
const UserRoleAssignPage = lazy(
  () => import("./pages/user-management/user-role-assign/UserRoleAssignPage")
);
const UserRoleAssignAddPage = lazy(
  () =>
    import("./pages/user-management/user-role-assign/v2/UserRoleAssignAddPage")
);
const UserRoleAssignEditPage = lazy(
  () =>
    import("./pages/user-management/user-role-assign/v2/UserRoleAssignEditPage")
);
const DistributionRegionManagementPage = lazy(
  () =>
    import(
      "./pages/distribution-region/distribution-region-management/DistributionRegionManagementPage"
    )
);
const DistributionRegionManagementAddPage = lazy(
  () =>
    import(
      "./pages/distribution-region/distribution-region-management/DistributionRegionManagementAddPage"
    )
);
const DistributionRegionManagementEditPage = lazy(
  () =>
    import(
      "./pages/distribution-region/distribution-region-management/DistributionRegionManagementEditPage"
    )
);
const WayUserAssignPage = lazy(
  () => import("./pages/distribution-region/way-user-assign/WayUserAssignPage")
);
const WayUserAssignAddPage = lazy(
  () =>
    import("./pages/distribution-region/way-user-assign/WayUserAssignAddPage")
);
const WayUserAssignEditPage = lazy(
  () =>
    import("./pages/distribution-region/way-user-assign/WayUserAssignEditPage")
);
const WarehousePage = lazy(
  () => import("./pages/warehouse-and-inventory/warehouse/WarehousePage")
);
const WarehouseAddPage = lazy(
  () => import("./pages/warehouse-and-inventory/warehouse/WarehouseAddPage")
);
const WarehouseEditPage = lazy(
  () => import("./pages/warehouse-and-inventory/warehouse/WarehouseEditPage")
);
const WarehouseUserAssignPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/warehouse-user-assign/WarehouseUserAssignPage"
    )
);
const WarehouseUserAssignAddPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/warehouse-user-assign/WarehouseUserAssignAddPage"
    )
);
const WarehouseUserAssignEditPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/warehouse-user-assign/WarehouseUserAssignEditPage"
    )
);
const PrimarySaleOrderPage = lazy(
  () => import("./pages/primary-sales-order/sales-order/PrimarySaleOrderPage")
);
const PrimarySaleOrderAddPage = lazy(
  () =>
    import("./pages/primary-sales-order/sales-order/PrimarySaleOrderAddPage")
);
const PrimarySaleOrderEditPage = lazy(
  () =>
    import("./pages/primary-sales-order/sales-order/PrimarySaleOrderEditPage")
);
const SecondarySaleOrderPage = lazy(
  () =>
    import("./pages/secondary-sales-order/sales-order/SecondarySaleOrderPage")
);
const SecondarySalesOrderAddPage = lazy(
  () =>
    import(
      "./pages/secondary-sales-order/sales-order/SecondarySalesOrderAddPage"
    )
);
const SecondarySalesOrderEditPage = lazy(
  () =>
    import(
      "./pages/secondary-sales-order/sales-order/SecondarySalesOrderEditPage"
    )
);
const DirectSalesOrderPage = lazy(
  () => import("./pages/direct-sales-order/sales-order/DirectSalesOrderPage")
);
const DirectSalesOrderAddPage = lazy(
  () => import("./pages/direct-sales-order/sales-order/DirectSalesOrderAddPage")
);
const DirectSalesOrderEditPage = lazy(
  () =>
    import("./pages/direct-sales-order/sales-order/DirectSalesOrderEditPage")
);
const PrimarySaleOrderConvertToInvoicePage = lazy(
  () => import("./pages/primary-sales-order/invoice/PrimaryInvoiceAddPage")
);
const PrimarySaleOrderInvoicePage = lazy(
  () => import("./pages/primary-sales-order/invoice/PrimaryInvoicePage")
);
const PrimarySaleOrderInvoiceEditPage = lazy(
  () => import("./pages/primary-sales-order/invoice/PrimaryInvoiceEditPage")
);
const SecondaryInvoiceAddPage = lazy(
  () => import("./pages/secondary-sales-order/invoice/SecondaryInvoiceAddPage")
);
const SecondaryInvoicePage = lazy(
  () => import("./pages/secondary-sales-order/invoice/SecondaryInvoicePage")
);
const SecondaryInvoiceEditPage = lazy(
  () => import("./pages/secondary-sales-order/invoice/SecondaryInvoiceEditPage")
);
const DirectInvoiceAddPage = lazy(
  () => import("./pages/direct-sales-order/invoice/DirectInvoiceAddPage")
);
const DirectInvoicePage = lazy(
  () => import("./pages/direct-sales-order/invoice/DirectInvoicePage")
);
const DirectInvoiceEditPage = lazy(
  () => import("./pages/direct-sales-order/invoice/DirectInvoiceEditPage")
);
const PaymentReceiveAddPage = lazy(
  () =>
    import(
      "./pages/primary-sales-order/payment-receive/PrimaryPaymentReceiveAddPage"
    )
);
const PrimaryPaymentReceivePage = lazy(
  () =>
    import(
      "./pages/primary-sales-order/payment-receive/PrimaryPaymentReceivePage"
    )
);
const SecondaryPaymentReceiveAddPage = lazy(
  () =>
    import(
      "./pages/secondary-sales-order/payment-receive/SecondaryPaymentReceiveAddPage"
    )
);
const SecondaryPaymentReceivePage = lazy(
  () =>
    import(
      "./pages/secondary-sales-order/payment-receive/SecondaryPaymentReceivePage"
    )
);
const DirectPaymentReceiveAddPage = lazy(
  () =>
    import(
      "./pages/direct-sales-order/payment-receive/DirectPaymentReceiveAddPage"
    )
);
const DirectPaymentReceivePage = lazy(
  () =>
    import(
      "./pages/direct-sales-order/payment-receive/DirectPaymentReceivePage"
    )
);
const StockImportPage = lazy(
  () => import("./pages/warehouse-and-inventory/stock-import/StockImportPage")
);
const StockImportAddPage = lazy(
  () =>
    import("./pages/warehouse-and-inventory/stock-import/StockImportAddPage")
);
const StockImportEditPage = lazy(
  () =>
    import("./pages/warehouse-and-inventory/stock-import/StockImportEditPage")
);
// purchase order module
const PurchaseOrderPage = lazy(
  () => import("./pages/purchase/purchase-order/PurchaseOrderPage")
);
const PurchaseOrderAddPage = lazy(
  () => import("./pages/purchase/purchase-order/PurchaseOrderAddPage")
);
const PurchaseOrderEditPage = lazy(
  () => import("./pages/purchase/purchase-order/PurchaseOrderEditPage")
);

const BillPage = lazy(() => import("./pages/purchase/bill/BillPage"));
const BillAddPage = lazy(() => import("./pages/purchase/bill/BillAddPage"));
const BillEditPage = lazy(() => import("./pages/purchase/bill/BillEditPage"));

const PaymentMadeAddPage = lazy(
  () => import("./pages/purchase/payment-made/PaymentMadeAddPage")
);
const PaymentMadePage = lazy(
  () => import("./pages/purchase/payment-made/PaymentMadePage")
);
//
const StockTransferPage = lazy(
  () =>
    import("./pages/warehouse-and-inventory/stock-transfer/StockTransferPage")
);
const StockTransferAddPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/stock-transfer/StockTransferAddPage"
    )
);
const StockTransferEditPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/stock-transfer/StockTransferEditPage"
    )
);
const StockBalancePage = lazy(
  () => import("./pages/warehouse-and-inventory/stock-balance/StockBalancePage")
);
const StockAdjustmentPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/stock-adjustment/StockAdjustmentPage"
    )
);
const StockAdjustmentAddPage = lazy(
  () =>
    import(
      "./pages/warehouse-and-inventory/stock-adjustment/StockAdjustmentAddPage"
    )
);
const PrimarySalesReturnPage = lazy(
  () => import("./pages/primary-sales-return/sales-return/SalesReturnPage")
);
const PrimarySalesReturnAddPage = lazy(
  () => import("./pages/primary-sales-return/sales-return/SalesReturnAddPage")
);
const PrimarySalesReturnEditPage = lazy(
  () => import("./pages/primary-sales-return/sales-return/SalesReturnEditPage")
);
const PrimarySalesReturnMakePaymentPage = lazy(
  () => import("./pages/primary-sales-return/make-payment/MakePaymentPage")
);
const PrimarySalesReturnMakePaymentAddPage = lazy(
  () => import("./pages/primary-sales-return/make-payment/MakePaymentAddPage")
);
const SecondarySalesReturnPage = lazy(
  () => import("./pages/secondary-sales-return/sales-return/SalesReturnPage")
);
const SecondarySalesReturnAddPage = lazy(
  () => import("./pages/secondary-sales-return/sales-return/SalesReturnAddPage")
);

const SecondarySalesReturnEditPage = lazy(
  () =>
    import("./pages/secondary-sales-return/sales-return/SalesReturnEditPage")
);
const SecondarySalesReturnMakePaymentPage = lazy(
  () => import("./pages/secondary-sales-return/make-payment/MakePaymentPage")
);
const SecondarySalesReturnMakePaymentAddPage = lazy(
  () => import("./pages/secondary-sales-return/make-payment/MakePaymentAddPage")
);
const DirectSalesReturnPage = lazy(
  () => import("./pages/direct-sales-return/sales-return/SalesReturnPage")
);
const DirectSalesReturnAddPage = lazy(
  () => import("./pages/direct-sales-return/sales-return/SalesReturnAddPage")
);
const DirectSalesReturnEditPage = lazy(
  () => import("./pages/direct-sales-return/sales-return/SalesReturnEditPage")
);
const DirectSalesReturnMakePaymentPage = lazy(
  () => import("./pages/direct-sales-return/make-payment/MakePaymentPage")
);
const DirectSalesReturnMakePaymentAddPage = lazy(
  () => import("./pages/direct-sales-return/make-payment/MakePaymentAddPage")
);
const ExpensePage = lazy(
  () => import("./pages/expense-management/ExpensePage")
);
const ExpenseEditPage = lazy(
  () => import("./pages/expense-management/ExpenseEditPage")
);
const TripManagementPage = lazy(
  () => import("./pages/trip/trip-management/TripManagementPage")
);

const TripManagementEditPage = lazy(
  () => import("./pages/trip/trip-management/TripManagementEditPage")
);
const TripSalesRequestPage = lazy(
  () => import("./pages/trip/trip-sales-request/TripSalesRequestPage")
);
const TripSalesRequestAddPage = lazy(
  () => import("./pages/trip/trip-sales-request/TripSalesRequestAddPage")
);
const TripSalesRequestEditPage = lazy(
  () => import("./pages/trip/trip-sales-request/TripSalesRequestEditPage")
);
const LoginPage = lazy(() => import("./pages/LoginPage"));
const OrderApprovalPage = lazy(
  () => import("./pages/primary-sales-order/order-approval/OrderApprovalPage")
);
const OrderApproalEditPage = lazy(
  () =>
    import("./pages/primary-sales-order/order-approval/OrderApprovalEditPage")
);
const ConsignmentContractPage = lazy(
  () =>
    import("./pages/consignment/consignment-contract/ConsignmentContractPage")
);
const ConsignmentContractAddPage = lazy(
  () =>
    import(
      "./pages/consignment/consignment-contract/ConsignmentContractAddPage"
    )
);
const ConsignmentContractEditPage = lazy(
  () =>
    import(
      "./pages/consignment/consignment-contract/ConsignmentContractEditPage"
    )
);
const ConsignmentApprovalPage = lazy(
  () =>
    import("./pages/consignment/consiginment-approval/ConsignmentApprovalPage")
);
const ConsignmentApprovalEditPage = lazy(
  () =>
    import(
      "./pages/consignment/consiginment-approval/ConsignmentApprovalEditPage"
    )
);
const TripUserAssignPage = lazy(
  () => import("./pages/trip/trip-user-assign/TripUserAssignPage")
);
const TripUserAssignAddPage = lazy(
  () => import("./pages/trip/trip-user-assign/TripUserAssignAddPage")
);
const TripUserAssignEditPage = lazy(
  () => import("./pages/trip/trip-user-assign/TripUserAssignEditPage")
);
const TripSalesReturnPage = lazy(
  () => import("./pages/trip-sales-return/sales-return/SalesReturnPage")
);
const TripSalesReturnAddPage = lazy(
  () => import("./pages/trip-sales-return/sales-return/SalesReturnAddPage")
);
const TripSalesReturnEditPage = lazy(
  () => import("./pages/trip-sales-return/sales-return/SalesReturnEditPage")
);
const TripSalesReturnMakePaymentPage = lazy(
  () => import("./pages/trip-sales-return/make-payment/MakePaymentPage")
);
const TripSalesReturnMakePaymentAddPage = lazy(
  () => import("./pages/trip-sales-return/make-payment/MakePaymentAddPage")
);
const TripSalesOrderPage = lazy(
  () => import("./pages/trip/trip-sales-order/TripSalesOrderPage")
);
const TripSalesOrderAddPage = lazy(
  () => import("./pages/trip/trip-sales-order/TripSalesOrderAddPage")
);
const TripSalesOrderEditPage = lazy(
  () => import("./pages/trip/trip-sales-order/TripSalesOrderEditPage")
);
const TripInvoicePage = lazy(
  () => import("./pages/trip/tirp-invoice/TripInvoicePage")
);
const TripInvoiceAddPage = lazy(
  () => import("./pages/trip/tirp-invoice/TripInvoiceAddPage")
);
const TripInvoiceEditPage = lazy(
  () => import("./pages/trip/tirp-invoice/TripInvoiceEditPage")
);
const TripPaymentReceivePage = lazy(
  () => import("./pages/trip/trip-payment-receive/TripPaymentReceivePage")
);
const TripPaymentReceiveAddPage = lazy(
  () => import("./pages/trip/trip-payment-receive/TripPaymentReceiveAddPage")
);
const SecondaryOrderApprovalPage = lazy(
  () => import("./pages/secondary-sales-order/order-approval/OrderApprovalPage")
);
const SecondaryOrderApprovalEditPage = lazy(
  () =>
    import("./pages/secondary-sales-order/order-approval/OrderApprovalEditPage")
);
const DirectOrderApprovalPage = lazy(
  () => import("./pages/direct-sales-order/order-approval/OrderApprovalPage")
);
const DirectOrderApprovalEditPage = lazy(
  () =>
    import("./pages/direct-sales-order/order-approval/OrderApprovalEditPage")
);
const SalesByProductPage = lazy(
  () => import("./pages/report/SalesByProductPage")
);
const SalesByProductCategoryPage = lazy(
  () => import("./pages/report/SalesByProductCategoryPage")
);
const GoodReturnNotePage = lazy(
  () => import("./pages/good-return/GoodReturnNotePage")
);
const GoodReturnNoteAddPage = lazy(
  () => import("./pages/good-return/GoodReturnNoteAddPage")
);
const GoodReturnNoteEditPage = lazy(
  () => import("./pages/good-return/GoodReturnNoteEditPage")
);

// Goods Request

const GoodsRequestNotePage = lazy(
  () => import("./pages/goods-request/goods-request-note/GoodsRequestNotePage")
);
const GoodsRequestNoteAddPage = lazy(
  () =>
    import("./pages/goods-request/goods-request-note/GoodsRequestNoteAddPage")
);
const GoodsRequestNoteEditPage = lazy(
  () =>
    import("./pages/goods-request/goods-request-note/GoodsRequestNoteEditPage")
);

const GoodsIssueNotePage = lazy(
  () => import("./pages/goods-request/goods-issue-note/GoodsIssueNotePage")
);
const GoodsIssueNoteAddPage = lazy(
  () => import("./pages/goods-request/goods-issue-note/GoodsIssueNoteAddPage")
);
const GoodsIssueNoteEditPage = lazy(
  () => import("./pages/goods-request/goods-issue-note/GoodsIssueNoteEditPage")
);

const GoodReceiveNotePage = lazy(
  () => import("./pages/good-return/GoodReceiveNotePage")
);
const GoodReceiveNoteAddPage = lazy(
  () => import("./pages/good-return/GoodReceiveNoteAddPage")
);
const GoodReceiveNoteEditPage = lazy(
  () => import("./pages/good-return/GoodReceiveNoteEditPage")
);
const DeliveryNotePage = lazy(
  () => import("./pages/goods-request/delivery-note/DeliveryNotePage")
);

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RequireAuth />,
      // errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <DashboardPage />,
        },
        {
          path: "/dashboard",
          element: <DashboardPage />,
        },
        // Office User
        {
          path: "/user-management/office-user",
          element: <OfficeUserPage />,
        },
        {
          path: "/user-management/office-user/add",
          element: <OfficeUserAddPage />,
        },
        {
          path: "/user-management/office-user/:id/edit",
          element: <OfficeUserEditPage />,
        },
        {
          path: "/user-management/office-user/:id/reset-password",
          element: <ResetPasswordPage />,
        },
        // Customer User
        {
          path: "/user-management/customer-user/customer",
          element: <CustomerNamePage />,
        },
        {
          path: "/user-management/customer-user/customer/:id",
          element: <CustomerUserPage />,
        },
        {
          path: "/user-management/customer-user/customer/:id/user/add",
          element: <CustomerUserAddPage />,
        },
        {
          path: "/user-management/customer-user/customer/:id/user/:userId/edit",
          element: <CustomerUserEditPage />,
        },
        // User Role Assign
        {
          path: "/user-management/user-role-assign",
          element: <UserRoleAssignPage />,
        },
        {
          path: "/user-management/user-role-assign/add",
          element: <UserRoleAssignAddPage />,
        },
        {
          path: "/user-management/user-role-assign/:assignId/edit",
          element: <UserRoleAssignEditPage />,
        },
        // Customer Category
        {
          path: "/customer/customer-channel",
          element: <CustomerCategoryPage />,
        },
        {
          path: "/customer/customer-channel/add",
          element: <CustomerCategoryAddPage />,
        },
        {
          path: "/customer/customer-channel/:id/edit",
          element: <CustomerCategoryEditPage />,
        },
        // Customer
        {
          path: "/customer/customer",
          element: <CustomerPage />,
        },
        {
          path: "/customer/customer/add",
          element: <CustomerAddPage />,
        },
        {
          path: "/customer/customer/:id/edit",
          element: <CustomerEditPage />,
        },

        // Supplier
        {
          path: "/supplier-management/supplier",
          element: <SupplierPage />,
        },
        {
          path: "/supplier-management/supplier/add",
          element: <SupplierAddPage />,
        },
        {
          path: "/supplier-management/supplier/:id/edit",
          element: <SupplierEditPage />,
        },
        // Business Unit
        {
          path: "/customer/business-unit",
          element: <BusinessUnitPage />,
        },
        {
          path: "/customer/business-unit/add",
          element: <BusinessUnitAddPage />,
        },
        {
          path: "/customer/business-unit/:id/edit",
          element: <BusinessUnitEditPage />,
        },
        // Product Category
        {
          path: "/product/product-category",
          element: <ProductCategoryPage />,
        },
        {
          path: "/product/product-category/add",
          element: <ProductCategoryAddPage />,
        },
        {
          path: "/product/product-category/:id/edit",
          element: <ProductCategoryEditPage />,
        },
        // Product Tag
        {
          path: "/product/product-tag",
          element: <ProductTagPage />,
        },
        {
          path: "/product/product-tag/add",
          element: <ProductTagAddPage />,
        },
        {
          path: "/product/product-tag/:id/edit",
          element: <ProductTagEditPage />,
        },
        // Brand
        {
          path: "/product/brand",
          element: <BrandPage />,
        },
        {
          path: "/product/brand/add",
          element: <BrandAddPage />,
        },
        {
          path: "/product/brand/:id/edit",
          element: <BrandEditPage />,
        },
        // Unit of Measurement
        {
          path: "/unit/unit-of-measurement",
          element: <UnitOfMeasurementPage />,
        },
        {
          path: "/unit/unit-of-measurement/add",
          element: <UnitOfMeasurementAddPage />,
        },
        {
          path: "/unit/unit-of-measurement/:id/edit",
          element: <UnitOfMeasurementEditPage />,
        },
        // Unit of Conversion
        {
          path: "/unit/unit-of-conversion",
          element: <UnitOfConversionPage />,
        },
        {
          path: "/unit/unit-of-conversion/add",
          element: <UnitOfConversionAddPage />,
        },
        {
          path: "/unit/unit-of-conversion/:id/edit",
          element: <UnitOfConversionEditPage />,
        },
        // Product Information
        {
          path: "/product/product-information",
          element: <ProductInformationPage />,
        },
        {
          path: "/product/product-information/add",
          element: <ProductInformationAddPage />,
        },
        {
          path: "/product/product-information/:id/edit",
          element: <ProductInformationEditPage />,
        },
        // Way
        {
          path: "/distribution-region/way-management",
          element: <WayManagementpage />,
        },
        {
          path: "/distribution-region/way-management/add",
          element: <WayManagmentAddPage />,
        },
        {
          path: "/distribution-region/way-management/:id/edit",
          element: <WayManagementEditPage />,
        },
        // Distribution Region
        {
          path: "/distribution-region/distribution-region-management",
          element: <DistributionRegionManagementPage />,
        },
        {
          path: "/distribution-region/distribution-region-management/add",
          element: <DistributionRegionManagementAddPage />,
        },
        {
          path: "/distribution-region/distribution-region-management/:id/edit",
          element: <DistributionRegionManagementEditPage />,
        },
        // Way User Assign
        {
          path: "/distribution-region/way-user-assign",
          element: <WayUserAssignPage />,
        },
        {
          path: "/distribution-region/way-user-assign/add",
          element: <WayUserAssignAddPage />,
        },
        {
          path: "/distribution-region/way-user-assign/:id/edit",
          element: <WayUserAssignEditPage />,
        },
        // Warehoue
        {
          path: "/stock-and-inventory/allocation-account",
          element: <WarehousePage />,
        },
        {
          path: "/stock-and-inventory/allocation-account/add",
          element: <WarehouseAddPage />,
        },
        {
          path: "/stock-and-inventory/allocation-account/:id/edit",
          element: <WarehouseEditPage />,
        },
        // Warehouse User Assign
        {
          path: "/stock-and-inventory/allocation-account-user-assign",
          element: <WarehouseUserAssignPage />,
        },
        {
          path: "/stock-and-inventory/allocation-account-user-assign/add",
          element: <WarehouseUserAssignAddPage />,
        },
        {
          path: "/stock-and-inventory/allocation-account-user-assign/:id/edit",
          element: <WarehouseUserAssignEditPage />,
        },
        //  Sale Order
        {
          path: "/sale-order/sale-order",
          element: <PrimarySaleOrderPage />,
        },
        {
          path: "/sale-order/sale-order/add",
          element: <PrimarySaleOrderAddPage />,
        },
        {
          path: "/sale-order/sale-order/:id/edit",
          element: <PrimarySaleOrderEditPage />,
        },
        {
          path: "/sale-order/sale-order/:id/edit/convert-to-invoice",
          element: <PrimarySaleOrderConvertToInvoicePage />,
        },
        // Sale Order Approval
        {
          path: "/sale-order/order-approval/:id/edit",
          element: <OrderApprovalEditPage />,
        },
        {
          path: "/sale-order/order-approval",
          element: <OrderApprovalPage />,
        },
        // Sale Order  Invoice
        {
          path: "/sale-order/invoice",
          element: <PrimarySaleOrderInvoicePage />,
        },
        {
          path: "/sale-order/invoice/:id/edit",
          element: <PrimarySaleOrderInvoiceEditPage />,
        },
        //  Sale Order Payment Receive
        {
          path: "/sale-order/invoice/:id/edit/make-payment-receive",
          element: <PaymentReceiveAddPage />,
        },
        {
          path: "/sale-order/payment-receive",
          element: <PrimaryPaymentReceivePage />,
        },

        // All Target
        {
          path: "/sale-target/all-target",
          element: <AllTargetPage />,
        },
        {
          path: "/sale-target/all-target/add",
          element: <AllTargetAddPage />,
        },
        {
          path: "/sale-target/all-target/:id/edit",
          element: <AllTargetEditPage />,
        },
        // Product Target
        {
          path: "/sale-target/product-target",
          element: <ProductTargetPage />,
        },
        {
          path: "/sale-target/product-target/add",
          element: <ProductTargetAddPage />,
        },
        {
          path: "/sale-target/product-target/:id/edit",
          element: <ProductTargetEditPage />,
        },
        // Stock Import
        {
          path: "/stock-and-inventory/stock-import",
          element: <StockImportPage />,
        },
        {
          path: "/stock-and-inventory/stock-import/add",
          element: <StockImportAddPage />,
        },
        {
          path: "/stock-and-inventory/stock-import/:id/edit",
          element: <StockImportEditPage />,
        },
        // Stock Transfer
        {
          path: "/stock-and-inventory/stock-transfer",
          element: <StockTransferPage />,
        },
        {
          path: "/stock-and-inventory/stock-transfer/add",
          element: <StockTransferAddPage />,
        },
        {
          path: "/stock-and-inventory/stock-transfer/:id/edit",
          element: <StockTransferEditPage />,
        },
        // Stock Balance
        {
          path: "/stock-and-inventory/stock-balance",
          element: <StockBalancePage />,
        },
        // Stock Adjustment
        {
          path: "/stock-and-inventory/stock-adjustment",
          element: <StockAdjustmentPage />,
        },
        {
          path: "/stock-and-inventory/stock-adjustment/add",
          element: <StockAdjustmentAddPage />,
        },
        // Primary Sales Return
        {
          path: "/primary-sales-return/sales-return",
          element: <PrimarySalesReturnPage />,
        },
        {
          path: "/primary-sales-return/sales-return/add",
          element: <PrimarySalesReturnAddPage />,
        },
        {
          path: "/primary-sales-return/sales-return/:id/edit",
          element: <PrimarySalesReturnEditPage />,
        },
        {
          path: "/primary-sales-return/make-payment",
          element: <PrimarySalesReturnMakePaymentPage />,
        },
        {
          path: "/primary-sales-return/sales-return/:id/edit/make-payment",
          element: <PrimarySalesReturnMakePaymentAddPage />,
        },
        //Sales Return
        {
          path: "/sales-return/sales-return",
          element: <SecondarySalesReturnPage />,
        },
        {
          path: "/sales-return/sales-return/add",
          element: <SecondarySalesReturnAddPage />,
        },
        {
          path: "/sales-return/sales-return/:id/edit",
          element: <SecondarySalesReturnEditPage />,
        },
        {
          path: "/sales-return/make-payment",
          element: <SecondarySalesReturnMakePaymentPage />,
        },
        {
          path: "/sales-return/sales-return/:id/edit/make-payment",
          element: <SecondarySalesReturnMakePaymentAddPage />,
        },
        // Dierct Sales Return
        {
          path: "/direct-sales-return/sales-return",
          element: <DirectSalesReturnPage />,
        },
        {
          path: "/direct-sales-return/sales-return/add",
          element: <DirectSalesReturnAddPage />,
        },
        {
          path: "/direct-sales-return/sales-return/:id/edit",
          element: <DirectSalesReturnEditPage />,
        },
        {
          path: "/direct-sales-return/make-payment",
          element: <DirectSalesReturnMakePaymentPage />,
        },
        {
          path: "/direct-sales-return/sales-return/:id/edit/make-payment",
          element: <DirectSalesReturnMakePaymentAddPage />,
        },
        // Expnese Management
        {
          path: "/expense-management/expense",
          element: <ExpensePage />,
        },
        {
          path: "/expense-management/expense/:id/edit",
          element: <ExpenseEditPage />,
        },
        {
          path: "/trip/vehicle-management",
          element: <VehiclePage />,
        },
        {
          path: "/trip/vehicle-management/add",
          element: <VehicleAddPage />,
        },
        {
          path: "/trip/vehicle-management/:id/edit",
          element: <VehicleEditPage />,
        },
        // Trip Management
        {
          path: "/trip/trip-proposal",
          element: <TripManagementPage />,
        },
        {
          path: "/trip/trip-proposal/add",
          element: <TripManagementAddPage />,
        },
        {
          path: "/trip/trip-proposal/:id/edit",
          element: <TripManagementEditPage />,
        },
        // Trip Approval
        {
          path: "/trip/trip-proposal-approval",
          element: <TripApprovalPage />,
        },
        {
          path: "/trip/trip-proposal-approval/:id/edit",
          element: <TripApprovalEditPage />,
        },
        // Trip Sales Request
        {
          path: "/trip/trip-sales-request",
          element: <TripSalesRequestPage />,
        },
        {
          path: "/trip/trip-sales-request/add",
          element: <TripSalesRequestAddPage />,
        },
        {
          path: "/trip/trip-sales-request/:id/edit",
          element: <TripSalesRequestEditPage />,
        },

        // Trip UserAssign
        {
          path: "/trip/trip-user-assign",
          element: <TripUserAssignPage />,
        },
        {
          path: "/trip/trip-user-assign/add",
          element: <TripUserAssignAddPage />,
        },
        {
          path: "/trip/trip-user-assign/:id/edit",
          element: <TripUserAssignEditPage />,
        },
        // Consignment Contract
        {
          path: "/consignment/consignment-contract",
          element: <ConsignmentContractPage />,
        },
        {
          path: "/consignment/consignment-contract/add",
          element: <ConsignmentContractAddPage />,
        },
        {
          path: "/consignment/consignment-contract/:id/edit",
          element: <ConsignmentContractEditPage />,
        },
        // Consignment Approval
        {
          path: "/consignment/consignment-approval",
          element: <ConsignmentApprovalPage />,
        },
        {
          path: "/consignment/consignment-approval/:id/edit",
          element: <ConsignmentApprovalEditPage />,
        },
        // Trip Sales Return
        {
          path: "/trip-sales-return/sales-return",
          element: <TripSalesReturnPage />,
        },
        {
          path: "/trip-sales-return/sales-return/add",
          element: <TripSalesReturnAddPage />,
        },
        {
          path: "/trip-sales-return/sales-return/:id/edit",
          element: <TripSalesReturnEditPage />,
        },
        {
          path: "/trip-sales-return/make-payment",
          element: <TripSalesReturnMakePaymentPage />,
        },
        {
          path: "/trip-sales-return/sales-return/:id/edit/make-payment",
          element: <TripSalesReturnMakePaymentAddPage />,
        },
        // Trip Sales Order
        {
          path: "/trip/sales-order",
          element: <TripSalesOrderPage />,
        },
        {
          path: "/trip/sales-order/add",
          element: <TripSalesOrderAddPage />,
        },
        {
          path: "/trip/sales-order/:id/edit",
          element: <TripSalesOrderEditPage />,
        },
        {
          path: "/trip/sales-order/:id/edit/convert-to-invoice",
          element: <TripInvoiceAddPage />,
        },
        // Trip Invoice
        {
          path: "/trip/invoice",
          element: <TripInvoicePage />,
        },
        {
          path: "/trip/invoice/:id/edit",
          element: <TripInvoiceEditPage />,
        },
        // Trip Payment Receive
        {
          path: "/trip/invoice/:id/edit/make-payment-receive",
          element: <TripPaymentReceiveAddPage />,
        },
        {
          path: "/trip/payment-receive",
          element: <TripPaymentReceivePage />,
        },
        //Consignment
        {
          path: "/consignment",
          element: <ConsignmentPage />,
        },
        {
          path: "/consignment/add",
          element: <ConsignmentAddPage />,
        },
        {
          path: "/consignment/:id/edit",
          element: <ConsignmentEditPage />,
        },
        {
          path: "/consignment/:id/edit/convert-to-invoice",
          element: <ConsignmentInvoiceAddPage />,
        },
        //Consignment  Invoice
        {
          path: "/consignment/invoice",
          element: <ConsignmentInvoicePage />,
        },
        {
          path: "/consignment/invoice/add",
          element: <ConsignmentInvoiceAddPage />,
        },
        {
          path: "/consignment/invoice/:id/edit",
          element: <ConsignmentInvoiceEditPage />,
        },
        //Consignment  Payment Receive
        {
          path: "/consignment/payment-receive",
          element: <ConsignmentPaymentReceivePage />,
        },
        {
          path: "/consignment/invoice/:id/edit/make-payment-receive",
          element: <ConsignmentPaymentReceiveAddPage />,
        },
        // Consignment Return
        {
          path: "consignment-return",
          element: <ConsignmentReturnPage />,
        },
        {
          path: "consignment-return/add",
          element: <ConsignmentReturnAddPage />,
        },
        {
          path: "consignment-return/:id/edit",
          element: <ConsignmentReturnEditPage />,
        },
        // Reports
        {
          path: "/reports/sales-by-product",
          element: <SalesByProductPage />,
        },
        {
          path: "/reports/sales-by-product-category",
          element: <SalesByProductCategoryPage />,
        },
        // Good Return
        {
          path: "/good-return/good-return-note",
          element: <GoodReturnNotePage />,
        },
        {
          path: "/good-return/good-return-note/add",
          element: <GoodReturnNoteAddPage />,
        },
        {
          path: "/good-return/good-return-note/:id/edit",
          element: <GoodReturnNoteEditPage />,
        },

        //Goods Request

        {
          path: "/goods-request/goods-request-note",
          element: <GoodsRequestNotePage />,
        },
        {
          path: "/goods-request/goods-request-note/add",
          element: <GoodsRequestNoteAddPage />,
        },
        {
          path: "/goods-request/goods-request-note/:id/edit",
          element: <GoodsRequestNoteEditPage />,
        },

        {
          path: "/goods-request/goods-request-note/:id/edit/make-issue",
          element: <GoodsIssueNoteAddPage />,
        },
        {
          path: "/goods-request/goods-issue-note",
          element: <GoodsIssueNotePage />,
        },

        {
          path: "/goods-request/goods-issue-note/:id/edit",
          element: <GoodsIssueNoteEditPage />,
        },
        {
          path: "/good-return/good-return-note/:id/edit/convert-to-receive-note",
          element: <GoodReceiveNoteAddPage />,
        },
        {
          path: "/good-return/good-receive-note",
          element: <GoodReceiveNotePage />,
        },
        {
          path: "/good-return/good-receive-note/:id/edit",
          element: <GoodReceiveNoteEditPage />,
        },

        {
          path: "/good-return/good-receive-note/:id/edit",
          element: <GoodReceiveNoteEditPage />,
        },

        // Marketing Promotion
        {
          path: "/marketing-promotion/plans",
          element: <MarketingPlanPage />,
        },
        {
          path: "/marketing-promotion/plans/add",
          element: <MarketingPlanAddPage />,
        },
        {
          path: "/marketing-promotion/plans/:id/edit",
          element: <MarketingPlanEditPage />,
        },
        {
          path: "/marketing-promotion/invoices",
          element: <MarketingInvoicePage />,
        },
        {
          path: "/marketing-promotion/invoices/:id/edit",
          element: <MarketingInvoiceEditPage />,
        },
        {
          path: "/marketing-promotion/good-requisition/:id/edit/make-invoice",
          element: <MarketingInvoiceAddPage />,
        },
        {
          path: "/marketing-promotion/payment-received",
          element: <MarketingPaymentReceivePage />,
        },
        {
          path: "/marketing-promotion/invoices/:id/edit/payment-received/add",
          element: <MarketingPaymentReceiveAddPage />,
        },
        {
          path: "/marketing-promotion/good-requisition",
          element: <MarketingGoodRequestPage />,
        },
        {
          path: "/marketing-promotion/good-requisition/:id/edit",
          element: <MarketingGoodRequestEditPage />,
        },

        // Delivery Note
        {
          path: "/goods-request/goods-delivery-note",
          element: <DeliveryNotePage />,
        },
        {
          path: "/goods-request/goods-delivery-note/:id/edit",
          element: <GoodsDeliveryNoteEditPage />,
        },
        {
          path: "/sale-promotion/plans",
          element: <SalePromotionPlanPage />,
        },
        {
          path: "/sale-promotion/plans/add",
          element: <SalePromotionPlanAddPage />,
        },
        {
          path: "/sale-promotion/plans/:id/edit",
          element: <SalePromotionPlanEditPage />,
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/access-denied",
      element: <AccessDeniedPage />,
    },
  ]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
