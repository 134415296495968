import PageWrapper from "@components/layouts/PageWrapper";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { Toast } from "@helpers/popups/Toast";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import {
  useApprovePrimaryOrderMutation,
  useUpdatePrimarySaleOrderMutation,
} from "@states/primary-sale-order/primary-sale-order.api";
import BackButton from "@components/buttons/BackButton";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { saleOrderSchema } from "@helpers/validation-schemas/primary-sale-order/sale-order.schema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomDateField from "@components/form/CustomDateField";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import useGetAllCustomers from "@hooks/customer/useGetAllCustomers";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import { useLazyGetProductByBarcodeQuery } from "@states/product/product.api";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@states/store";
import { thousandSeperator } from "@utils/general/digit-separators";
import CustomInputField from "@components/form/CustomInputField";
import CustomButton from "@components/buttons/CustomButton";
import { productDetailColumns } from "@helpers/columns/primary-sale-order/product-detail.columns";
import useGetOnePrimarySaleorder from "@hooks/primary-sales-order/useGetOnePrimarySaleOrder";
import {
  addFormValues,
  addProductDetail,
  removeProductDetail,
  resetFormValues,
  updateProductDetail,
} from "@states/common/common.slice";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { TProduct } from "@type-defs/product/TProduct";
import { PRIMARY_SALE_ORDER } from "@utils/constants/primary-sale-order.constants";
import { calculatePercentage } from "@utils/general/cal-percent";
import useGetOneSecondarySalesorder from "@hooks/secondary-sales-order/useGetOneSecondarySaleOrder";
import { useLazyGetCustomerQuery } from "@states/customer/customer.api";
import { useApproveSecondaryOrderMutation } from "@states/secondary-sale-order/secondary-sale-order.api";

import useGetAllPaymentTypes from "@hooks/common/useGetAllPaymentTypes";
import AuthComponent from "@components/auth/component";

import PromotionCard from "@components/extra/sales-order/PromotionCard";
import DeleteButton from "@components/buttons/DeleteButton";
import useGetAllProducts from "@hooks/product/useGetAllProducts";

import {
  useGetAllPaymentTermsQuery,
  useGetWarehousesByUserQuery,
  useLazyGetProductDetailByWarehouseQuery,
  useGetAllSaleTypeQuery,
  useGetCustomerByUserQuery,
  useLazyGetAllPromotionNameListQuery,
  useLazyGetAllPromotionProductListQuery,
} from "@states/common/common.api";
import SaleOrderPromotionDetailModal from "@components/modals/sales-order/SaleOrderPromotionDetailModal";
import PromotionIcon from "@assets/images/Icon.png";
import { useDraggable } from "react-use-draggable-scroll";
import { selectCurrentUser } from "@states/auth/auth.slice";

import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";
type FormFields = z.infer<typeof saleOrderSchema>;

const OrderApprovalEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productBarcodeRef = useRef(null);
  const [businessUnits, setBusinessUnits] = useState<any>([]);
  const user = useSelector(selectCurrentUser);
  const storedUser = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_KEY);
  const userData = user || (storedUser ? JSON.parse(storedUser!) : null);
  const { data: userWarehouseData, error: fetchUserWarehousesError } =
    useGetWarehousesByUserQuery({
      user_id: userData?.user_id,
    });
  const { formValues } = useSelector((state: RootState) => state.common);

  const {
    secondarySalesOrderData,
    secondarySalesOrderRefetch,
    secondarySalesOrderLoading,
  } = useGetOneSecondarySalesorder(id!);
  const tableContainerRef = useRef<any>();
  const { events } = useDraggable(tableContainerRef);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [autoSelectedRangeByAmount, setAutoSelectedRangeByAmount] =
    useState("0");
  const [autoSelectedRangeByQty, setAutoSelectedRangeByQty]: any =
    useState("0");
  const [baseQty, setBaseQty]: any = useState(1);
  const [selectedPromotionItemList, setSelectedPromotionItemList] = useState(
    []
  );
  const [
    getProductDetailByWarehouse,
    {
      data: productDetailByWarehouseData,
      error: productDetailByWarehouseError,
      isLoading: productDetailByWarehouseLoading,
    },
  ] = useLazyGetProductDetailByWarehouseQuery();
  const [customerChannelId, setCustomerChannleId] = useState("");
  const [wayId, setWayId] = useState("");
  const defaultValues = {
    secondary_sales_order_id: secondarySalesOrderData?.secondary_sales_order_id,
    sales_date: secondarySalesOrderData?.sales_date,
    sale_promotion_id: secondarySalesOrderData?.sale_promotion_id,
    //@ts-ignore
    promotion_name: secondarySalesOrderData?.promotion_name,
    customer_id: secondarySalesOrderData?.customer_id,
    description: secondarySalesOrderData?.description,
    business_unit_id: secondarySalesOrderData?.business_unit_id,
    sale_type_id: secondarySalesOrderData?.sale_type_id,
    payment_type_id: secondarySalesOrderData?.payment_type_id,
    status: secondarySalesOrderData?.status,
    reason: secondarySalesOrderData?.reason,
    payment_terms_id: secondarySalesOrderData?.payment_terms_id,
    customer_name:
      secondarySalesOrderData?.customer_first_name +
      " " +
      secondarySalesOrderData?.customer_last_name,
    product_detail: secondarySalesOrderData?.product_detail,
    sub_total: secondarySalesOrderData?.sub_total,
    grand_total_amount: secondarySalesOrderData?.grand_total_amount,
    discount_amount: secondarySalesOrderData?.discount_amount,
    tax_amount: secondarySalesOrderData?.tax_amount,
    other_charges: secondarySalesOrderData?.other_charges,
    discount_type: secondarySalesOrderData?.discount_type,
    tax_type: secondarySalesOrderData?.tax_type || "AMT",
    credit_limit: secondarySalesOrderData?.credit_limit,
    credit_balance: secondarySalesOrderData?.credit_balance,
    current_amount: secondarySalesOrderData?.grand_total_amount,
    discount: secondarySalesOrderData?.discount,
    tax: secondarySalesOrderData?.tax,
  };

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<FormFields>({
    defaultValues,
    resolver: zodResolver(saleOrderSchema),
  });

  const { append, remove, update } = useFieldArray({
    control,
    name: "product_detail",
  });

  const [
    approvePrimaryOrder,
    { isLoading, error: updateError, isSuccess, data },
  ] = useApproveSecondaryOrderMutation();
  const [getOneCustomer, { data: getOneCustomerData }] =
    useLazyGetCustomerQuery();
  const { customerData } = useGetAllCustomers({ limit: 1000 });
  const { data: paymentTermData } = useGetAllPaymentTermsQuery();
  const { paymentTypeData } = useGetAllPaymentTypes();
  const { data: saleTypeData } = useGetAllSaleTypeQuery();
  const { productData: productGetAllData } = useGetAllProducts({
    limit: 10000,
  });

  const [
    getAllPromotionProductList,
    {
      data: promotionProductData,
      error: promotionProductDataError,
      isLoading: promotionProductDataLoading,
    },
  ]: any = useLazyGetAllPromotionProductListQuery();
  const [
    getAllPromotionNameList,
    {
      data: PromotionData,
      error: getAllPromotioDataError,
      isLoading: promotioDataLoading,
    },
  ]: any = useLazyGetAllPromotionNameListQuery();
  const [
    getProductByBarcode,
    { data: productData, isLoading: productLoading },
  ] = useLazyGetProductByBarcodeQuery();

  useErrorHandling(
    ...castTypeArr<TFailResponse>([updateError, productDetailByWarehouseError])
  );

  useEffect(() => {
    if (formValues?.customer_id) {
      getOneCustomer({
        id: formValues?.customer_id,
      });
    }
  }, [formValues?.customer_id]);

  useEffect(() => {
    if (getOneCustomerData?.data) {
      setCustomerChannleId(getOneCustomerData?.data?.customer_category_id);

      setBusinessUnits(getOneCustomerData?.data?.business_units || []);
    }
  }, [getOneCustomerData]);

  useEffect(() => {
    if (formValues?.business_unit_id) {
      let filterBusinessUnit = businessUnits.find((bu: any) => {
        return bu.business_unit_id == formValues?.business_unit_id;
      });
      if (filterBusinessUnit?.way_id) {
        setWayId(filterBusinessUnit.way_id);
      }
    }
  }, [formValues?.business_unit_id, businessUnits]);
  useEffect(() => {
    if (id) {
      secondarySalesOrderRefetch();
    }
  }, [id, secondarySalesOrderRefetch]);

  useEffect(() => {
    if (isSuccess && data) {
      Toast.fire({
        title: data.message,
        icon: "success",
      });

      navigate(PRIMARY_SALE_ORDER.BASE_PATH, {
        state: {
          reload: true,
        },
      });
    }
  }, [isSuccess, data, navigate, Toast]);

  useEffect(() => {
    const pd = defaultValues?.product_detail?.map((dtl: any) => ({
      product_id: dtl.product_id,
      warehouse_id: dtl.warehouse_id,
      is_promotion_item: dtl.is_promotion_item || false,
      is_promotion_item_list: dtl.is_promotion_item_list || [],
      unit_type_data: dtl.unit_type_data,
      warehouse_name: dtl.warehouse_name,
      available_qty: dtl.available_qty || 0,
      old_available_qty: dtl.available_qty || 0,
      //@ts-ignore
      // available_qty : productDetailByWarehouseData?.data?.available_qty,
      product_code: dtl.product_code,
      product_name: dtl.product_name,
      unit_name: dtl.unit_name,
      unit_id: dtl.unit_id,
      sales_price: dtl.sales_price,
      qty: dtl.qty,
      amount: dtl.amount,
      discount_type: dtl.discount_type,
      discount_amount: dtl.discount_amount,
      tax_type: dtl.tax_type || "AMT",
      tax_amount: dtl.tax_amount,
      total_amount: dtl.total_amount,
      discount: dtl.discount,
      tax: dtl.tax,
    }));

    dispatch(addFormValues({ ...defaultValues, product_detail: pd }));

    reset({ ...defaultValues, product_detail: pd });
  }, [secondarySalesOrderData, reset, dispatch]);
  useEffect(() => {
    const pd = defaultValues?.product_detail?.map((dtl: any) => {
      if (dtl.product_id && dtl.warehouse_id) {
        getProductDetailByWarehouse({
          product_id: dtl.product_id,
          warehouse_id: dtl.warehouse_id,
        });
      }
      return {
        product_id: dtl.product_id,
        warehouse_id: dtl.warehouse_id,
        is_promotion_item: dtl.is_promotion_item || false,
        is_promotion_item_list: dtl.is_promotion_item_list || [],
        unit_type_data: dtl.unit_type_data,
        warehouse_name: dtl.warehouse_name,
        available_qty: dtl.available_qty || 0,
        old_available_qty: dtl.available_qty || 0,
        //@ts-ignore
        // available_qty : productDetailByWarehouseData?.data?.available_qty,
        product_code: dtl.product_code,
        product_name: dtl.product_name,
        unit_name: dtl.unit_name,
        unit_id: dtl.unit_id,
        sales_price: dtl.sales_price,
        qty: dtl.qty,
        amount: dtl.amount,
        discount_type: dtl.discount_type,
        discount_amount: dtl.discount_amount,
        tax_type: dtl.tax_type || "AMT",
        tax_amount: dtl.tax_amount,
        total_amount: dtl.total_amount,
        discount: dtl.discount,
        tax: dtl.tax,
      };
    });
    console.log("pd==>", pd);
    dispatch(addFormValues({ ...defaultValues, product_detail: pd }));

    reset({ ...defaultValues, product_detail: pd });
  }, [secondarySalesOrderData, reset, dispatch]);
  useEffect(() => {
    const productResponse = productData as any as TSuccessResponse<TProduct>;

    if (productResponse && productResponse.data.length > 0) {
      const product = productResponse.data[0];

      const existingProductIds = formValues?.product_detail?.map(
        (pd: any) => pd.product_id
      );

      if (!existingProductIds?.includes(product.product_id)) {
        const productDetail = {
          product_id: product.product_id,
          product_code: product.product_code,
          product_name: product.product_name,
          unit_id: product.unit_id,
          unit_name: product.unit_name,
          sales_price: product.secondary_sales_price,
          qty: 5,
          amount: 5 * product.secondary_sales_price,
          discount: 0,
          discount_amount: 0,
          discount_type: "AMT",
          tax: 0,
          tax_type: "AMT",
          tax_amount: 0,
          total_amount: 5 * product.secondary_sales_price,
        };

        append(productDetail);
        dispatch(addProductDetail(product));
      }

      //@ts-ignore
      productBarcodeRef.current.value = "";
    }
  }, [productData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { product_detail, ...theRest } = value;
      const targetIndex = Number(name?.split(".")?.[1]);
      const changeColumn = name?.split(".")?.[2];

      let targetProductDetail: any = product_detail![targetIndex!];
      console.log("watch targetProductDetail", targetProductDetail);

      let totalBonus = 0;
      let totalCashback = 0;

      if (targetProductDetail && product_detail) {
        let baseUnit = targetProductDetail.unit_type_data.find(
          (unit: any) => unit.unit_id == targetProductDetail?.unit_id
        );
        let base_qty = parseInt(baseUnit?.base_qty) || 1;

        if (changeColumn === "unit_id") {
          let old_available_qty = targetProductDetail.old_available_qty;
          targetProductDetail.available_qty = Math.floor(
            old_available_qty / base_qty
          );
        }

        let qty = targetProductDetail.qty || 0;
        let amount =
          qty > targetProductDetail.available_qty
            ? targetProductDetail.available_qty
            : qty;
        amount =
          qty === 0 ? 0 : qty * targetProductDetail.sales_price * base_qty;
        const updatedProductAmount = amount;

        if (changeColumn === "product_id") {
          const foundProduct = productGetAllData?.find(
            (product) => product.product_id == targetProductDetail.product_id
          );

          if (foundProduct) {
            targetProductDetail = {
              product_id: foundProduct.product_id,
              unit_id: foundProduct.unit_id,
              unit_type_id: foundProduct.unit_type_id,
              unit_type_data: foundProduct.unit_type_data,
              warehouse_id: targetProductDetail.warehouse_id,
              sales_price: foundProduct.secondary_sales_price,
              qty: targetProductDetail.qty || 0,
              available_qty: targetProductDetail.available_qty || 0,
              product_name: foundProduct.product_name,
              unit_name: foundProduct.unit_name,
              tax: foundProduct.secondary_sales_tax,
              tax_type: foundProduct.secondary_sales_tax_type || "AMT",
              discount: targetProductDetail.discount || 0,
              discount_type: targetProductDetail.discount_type || "AMT",
              amount: 0,
              total_amount: 0,
            };

            if (promotionProductData) {
              for (let detail of promotionProductData?.details) {
                let promotionItem = detail?.sku.find(
                  (sk: any) => sk.value == foundProduct.unit_id
                );

                if (promotionItem) {
                  targetProductDetail.is_promotion_item = true;

                  // Fix: Ensure it's an array for iteration
                  targetProductDetail.is_promotion_item_list = { ...detail };
                } else {
                  targetProductDetail.is_promotion_item = false;
                }
              }
            }
          }
        }

        if (changeColumn === "warehouse_id" || changeColumn === "product_id") {
          if (
            targetProductDetail.warehouse_id &&
            targetProductDetail.product_id
          ) {
            getProductDetailByWarehouse({
              product_id: targetProductDetail.product_id,
              warehouse_id: targetProductDetail.warehouse_id,
            });
          }
        }
        // Fix: Ensure is_promotion_item_list is iterable (array)
        // if (
        //   targetProductDetail?.is_promotion_item === true &&
        //   Array.isArray(targetProductDetail?.is_promotion_item_list)
        // ) {
        //   for (const promotionItem of targetProductDetail.is_promotion_item_list) {
        //     console.log("promotionItem", promotionItem); // Log promotionItem
        //     if (Array.isArray(promotionItem.info)) {
        //       for (const item of promotionItem.info) {
        //         console.log("item", item); // Log individual item

        //         if (promotionItem.range_type === "Unit") {
        //           let range = qty * base_qty;
        //           console.log("Calculated range:", range);

        //           // Check if range falls within sale_range_from and sale_range_to
        //           if (
        //             range >= item.sale_range_from &&
        //             range <= item.sale_range_to
        //           ) {
        //             console.log(
        //               "Valid range:",
        //               range,
        //               "Bonus:",
        //               item.bonus,
        //               "Cashback:",
        //               item.cashback
        //             );
        //             totalBonus += item.bonus || 0;
        //             totalCashback += item.cashback || 0;
        //           } else {
        //             console.log("Invalid range:", range, "For item", item);
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
        const updatedProductDiscount =
          targetProductDetail.discount_type === "%"
            ? updatedProductAmount * ((targetProductDetail.discount || 0) / 100)
            : targetProductDetail.discount || 0;

        const updatedProductTax =
          targetProductDetail.tax_type === "%"
            ? updatedProductAmount * ((targetProductDetail.tax || 0) / 100)
            : targetProductDetail.tax || 0;

        let updatedTotalAmount =
          updatedProductAmount + updatedProductTax - updatedProductDiscount;
        if (isNaN(updatedTotalAmount)) updatedTotalAmount = 0;

        update(targetIndex, {
          ...targetProductDetail,
          discount_amount: updatedProductDiscount,
          tax_amount: updatedProductTax,
          amount: updatedProductAmount,
          base_qty: base_qty,
          total_amount: updatedTotalAmount,
        });

        updateProductDetail({
          index: targetIndex,
          product_detail: {
            ...targetProductDetail,
            base_qty: base_qty,
            discount_amount: updatedProductDiscount,
            tax_amount: updatedProductTax,
            amount: updatedProductAmount,
            total_amount: updatedTotalAmount,
          },
        });
      }

      const subTotal =
        product_detail?.reduce(
          (acc, cur) => acc + (cur?.total_amount || 0),
          0
        ) || 0;

      let discountAmount = 0;
      if (theRest.discount_type === "%") {
        discountAmount =
          subTotal * (Math.min(theRest.discount || 0, 100) / 100);
      } else {
        discountAmount = Math.min(theRest.discount || 0, subTotal);
      }

      const taxAmount =
        theRest.tax_type === "%"
          ? subTotal * (theRest.tax! / 100)
          : theRest.tax || 0;

      const otherCharges = theRest.other_charges || 0;
      if (Array.isArray(product_detail) && product_detail.length) {
        for (const product of product_detail) {
          if (!product?.is_promotion_item) continue;

          // Ensure is_promotion_item_list exists and has `info`
          const promotionItemList = product.is_promotion_item_list;
          if (!promotionItemList || !Array.isArray(promotionItemList.info))
            continue;

          let amt = product?.amount || 0;
          let pqty = product?.qty || 0;
          let bQty = product?.base_qty || 1;
          let lastValidInfo =
            promotionItemList.info[promotionItemList.info.length - 1]; // Last index fallback

          for (const info of promotionItemList.info) {
            if (promotionItemList.range_type === "MMK") {
              if (amt >= info.sale_range_from && amt <= info.sale_range_to) {
                totalBonus += info.bonus || 0;
                totalCashback += info.cashback || 0;
                break; // Stop checking further once a valid range is found
              }
            }

            if (promotionItemList.range_type === "Unit") {
              if (
                pqty * bQty >= info.sale_range_from &&
                pqty * bQty <= info.sale_range_to
              ) {
                totalBonus += info.bonus || 0;
                totalCashback += info.cashback || 0;
                break; // Stop checking further once a valid range is found
              }
            }
          }

          // If `pqty` is greater than all `sale_range_to`, apply last valid bonus
          if (
            promotionItemList.range_type === "Unit" &&
            pqty * bQty > lastValidInfo.sale_range_to
          ) {
            totalBonus += lastValidInfo.bonus || 0;
            totalCashback += lastValidInfo.cashback || 0;
          }

          if (
            promotionItemList.range_type === "MMK" &&
            amt > lastValidInfo.sale_range_to
          ) {
            totalBonus += lastValidInfo.bonus || 0;
            totalCashback += lastValidInfo.cashback || 0;
          }
        }
      }
      dispatch(
        addFormValues({
          ...theRest,
          product_detail: product_detail?.map((pd) => ({ ...pd })),
          sub_total: subTotal,
          discount_amount: discountAmount,
          discount: theRest.discount || 0,
          tax_amount: taxAmount,
          tax: theRest.tax || 0,
          other_charges: otherCharges,
          bouns: totalBonus,
          cash_back: totalCashback,
          grand_total_amount:
            subTotal + otherCharges + taxAmount - discountAmount,
        })
      );
    });

    return () => {
      subscription.unsubscribe();
      // dispatch(resetFormValues());
    };
  }, [
    watch,
    dispatch,
    productGetAllData,
    getProductDetailByWarehouse,
    promotionProductData,
  ]);
  const handleRemove = (index: number) => {
    remove(index);
    dispatch(removeProductDetail(index));
  };
  useEffect(() => {
    if (formValues?.sale_promotion_id) {
      getAllPromotionProductList({
        sale_promotion_id: formValues?.sale_promotion_id,
      });
    }
  }, [formValues?.sale_promotion_id]);
  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const barcode = e.target.value;

    getProductByBarcode({ barcode });
  };
  useEffect(() => {
    if (wayId && customerChannelId) {
      console.log("start ");
      getAllPromotionNameList({
        customer_category_id: customerChannelId,
        way_id: wayId,
      });
      console.log("end ");
    }
  }, [wayId, customerChannelId]);

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { product_detail, discount, tax, ...rest } = formValues;

        const trimmedValues = {
          status: formValues.status,
          credit_balance: formValues.credit_balance,
          current_amount: formValues.current_amount,
          customer_id: formValues.customer_id,
          reason: formValues.reason,
        };

        await approvePrimaryOrder({
          id: id!,
          body: trimmedValues,
        });
      } catch (error) {
        console.log("Error", error);
      }
    },
    [approvePrimaryOrder, isSuccess, Toast, navigate, formValues]
  );

  // console.log(formValues, 'formValues');

  // console.log(secondarySalesOrderData, 'secondarySalesOrderData');

  return (
    <>
      <AuthComponent
        action="edit"
        module="Sales Order"
        subModule="Order Approval"
        page
      >
        <SaleOrderPromotionDetailModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          data={selectedPromotionItemList}
          amount={autoSelectedRangeByAmount}
          qty={autoSelectedRangeByQty}
          base_qty={baseQty}
        />

        <PageWrapper>
          {(isLoading || productLoading || secondarySalesOrderLoading) && (
            <LoadingSpinner />
          )}
          <BackButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between mb-4">
              <h3 className="ml-2 text-2xl font-[600] text-primary-dark uppercase">
                Edit Sale Order Approval
              </h3>
            </div>
            <div className="grid grid-cols-6 gap-x-4 gap-y-8">
              <CustomDateField
                disabled
                errors={errors}
                name="sales_date"
                label="Sale Date"
                register={register}
                key="sales_date"
                //@ts-ignore
                control={control}
                value={secondarySalesOrderData?.sales_date}
              />
              <CustomSelectField
                disabled
                required
                errors={errors}
                name="sale_type_id"
                label="Sale Type"
                placeHolder="Select Sale type"
                //@ts-ignore
                control={control}
                options={saleTypeData?.map((item: any) => ({
                  label: item.sale_type_name,
                  value: item.sale_type_id,
                }))}
                value={secondarySalesOrderData?.sale_type_id}
              />
              <CustomSearchModalChooseField
                disabled
                key={"customer_id"}
                errors={errors}
                colSpan={2}
                name={"customer_id"}
                label="Customer Name"
                title={"Customer List"}
                columns={[
                  {
                    name: "customer_name",
                    columnName: "Customer Name",
                  },
                  {
                    name: "phone_number",
                    columnName: "Phone Number",
                  },
                ]}
                register={register}
                // borderColor={field.borderColor}
                placeHolder="Choose customer name"
                setValue={setValue}
                data={customerData?.map((customer) => ({
                  customer_id: customer.customer_id,
                  customer_name:
                    customer.customer_first_name +
                    " " +
                    (customer.customer_last_name || "..."),
                  phone_number: customer.customer_phone1,
                }))}
                columnName={"Customer Name"}
                idName={"customer_id"}
                // value={field.value}
                nameName={"customer_name"}
                value={
                  secondarySalesOrderData?.customer_first_name +
                  " " +
                  (secondarySalesOrderData?.customer_last_name || "...")
                }
              />
              <CustomSelectField
                disabled
                errors={errors}
                name="business_unit_id"
                label="Business Unit"
                placeHolder="Select business unit"
                //@ts-ignore
                control={control}
                options={businessUnits?.map((bu: any) => ({
                  label: bu.business_unit_name,
                  value: bu.business_unit_id,
                }))}
              />
              <CustomSelectField
                disabled
                errors={errors}
                name="payment_type_id"
                label="Payment Type"
                placeHolder="Select payment type"
                //@ts-ignore
                control={control}
                options={paymentTypeData?.map((item: any) => ({
                  label: item.payment_type_name,
                  value: item.payment_type_id,
                }))}
                value={secondarySalesOrderData?.payment_type_id}
              />
              <CustomSelectField
                disabled
                errors={errors}
                name="payment_terms_id"
                label="Payment Term"
                placeHolder="Select payment term"
                //@ts-ignore
                control={control}
                options={paymentTermData?.map((item: any) => ({
                  label: item.payment_terms,
                  value: item.payment_terms_id,
                }))}
                value={secondarySalesOrderData?.payment_terms_id}
              />

              {/* <CustomTextAreaField
                        disabled
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    /> */}
              <CustomTextAreaField
                disabled
                key={"description"}
                errors={errors}
                colSpan={6}
                name="description"
                register={register}
              />
              <div className="flex justify-between col-span-6">
                <h3 className="ml-2 font-[600] text-primary-dark">
                  <span className=" text-[1.1rem]">Add Sale Order Detail</span>
                </h3>
              </div>
              <div className="flex flex-col justify-between col-span-6">
                <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                  <span className=" text-[1.3rem]">Product Detail</span>
                </h3>
                {/* <div className={`flex flex-col p-2 mt-5 w-[25%]`}>
                            <label
                                className="mb-2 text-gray-700"
                                style={{ textTransform: 'capitalize' }}
                                htmlFor="name"
                            >
                                Product Barcode
                            </label>
                            <input
                                disabled
                                ref={productBarcodeRef}
                                onChange={handleBarcodeChange}
                                className={`px-2 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                            />
                        </div> */}
              </div>
              <div className="-mt-4 col-span-full">
                {/* <DataTable
                            className="pb-4"
                            responsive
                            striped
                            //@ts-ignore
                            columns={productDetailColumns(
                                errors,
                                register,
                                setValue,
                                handleRemove,
                                control,
                                true
                            )}
                            //@ts-ignore
                            data={formValues?.product_detail?.map((f, i) => ({
                                ...f,
                                index: i,
                            }))}
                        /> */}
                <div
                  ref={tableContainerRef}
                  {...events}
                  className="w-[100%] mx-auto cursor-grab active:cursor-grabbing overflow-x-auto hide-scrollbar"
                >
                  <table className="min-w-full overflow-x-scroll custom-table">
                    <thead className="h-12 py-3 text-xs font-light text-netural-600 bg-neutral-100">
                      <th className="text-xs font-light 2xl:text-sm ">No</th>

                      <th className="text-xs font-light 2xl:text-sm ">
                        Product Name
                      </th>
                      <th className="text-xs font-light text-nowrap 2xl:text-sm">
                        Allocation Account Name
                      </th>
                      <th className="text-xs font-light 2xl:text-sm">Unit</th>
                      <th className="text-xs font-light 2xl:text-sm">
                        Sales Price
                      </th>
                      <th className="text-xs font-light 2xl:text-sm">Qty</th>
                      <th className="text-xs font-light text-nowrap 2xl:text-sm">
                        Available Qty
                      </th>
                      <th className="text-xs font-light 2xl:text-sm">Amount</th>
                      <th className="text-xs font-light 2xl:text-sm">
                        Discount
                      </th>
                      <th className="text-xs font-light 2xl:text-sm">Tax</th>
                      <th className="text-xs font-light 2xl:text-sm">
                        Total Amount
                      </th>
                      <th className="text-xs font-light 2xl:text-sm">Action</th>
                    </thead>
                    <tbody className="text-center">
                      {formValues?.product_detail?.map(
                        (product_detail: any, i: number) => {
                          return (
                            <tr key={i}>
                              {product_detail?.is_promotion_item == true ? (
                                <td>
                                  <div className="w-[120px] flex justify-center items-center ">
                                    <img
                                      src={PromotionIcon}
                                      alt="promotion_icon"
                                      className=" object-cover cursor-pointer"
                                      onClick={() => {
                                        setSelectedPromotionItemList(
                                          product_detail?.is_promotion_item_list ||
                                            []
                                        );
                                        setAutoSelectedRangeByAmount(
                                          product_detail?.amount
                                        );
                                        setAutoSelectedRangeByQty(
                                          product_detail?.qty
                                        );
                                        setBaseQty(
                                          product_detail?.base_qty || 1
                                        );
                                        setIsModalOpen(true);
                                      }}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td>-</td>
                              )}
                              <td>
                                <CustomSelectField
                                  disabled
                                  options={
                                    productGetAllData &&
                                    productGetAllData?.map((product: any) => {
                                      return {
                                        label: ` (${
                                          product.product_code_prefix || ""
                                        }${product.product_code})`,
                                        value: product.product_id,
                                      };
                                    })
                                  }
                                  classNames="w-[100%] min-w-[250px]"
                                  noLabel
                                  errors={errors}
                                  name={`product_detail.${i}.product_id`}
                                  //@ts-ignore
                                  control={control}
                                />
                                {/* @ts-ignore */}
                                {errors?.product_detail?.[i].product_id && (
                                  <div className="mt-2 font-[300] italic text-[0.7rem] text-red-600">
                                    {
                                      errors?.product_detail?.[i]?.product_id
                                        ?.message
                                    }
                                  </div>
                                )}
                              </td>
                              <td>
                                <CustomSearchModalChooseField
                                  disabled
                                  key={`product_detail.${i}.warehouse_id`}
                                  noLabel
                                  classNames="min-w-[300px] w-[100%] my-2 ml-2"
                                  required
                                  errors={errors}
                                  colSpan={2}
                                  name={`product_detail.${i}.warehouse_id`}
                                  label="Allocation Account Name"
                                  title={"Allocation Account List"}
                                  columns={[
                                    {
                                      name: "warehouse_name",
                                      columnName: "Allocation Account Name",
                                    },
                                    {
                                      name: "warehouse_address",
                                      columnName: "Address",
                                    },
                                  ]}
                                  register={register}
                                  placeHolder="Allocation account"
                                  setValue={setValue}
                                  //@ts-ignore
                                  data={userWarehouseData?.data?.map(
                                    (warehouse: any) => ({
                                      warehouse_id: warehouse.warehouse_id,
                                      warehouse_name: warehouse.warehouse_name,
                                      warehouse_address:
                                        warehouse.warehouse_address,
                                    })
                                  )}
                                  columnName={"Warehouse Name"}
                                  idName={"warehouse_id"}
                                  nameName={"warehouse_name"}
                                  value={
                                    //@ts-ignore
                                    product_detail?.warehouse_name
                                  }
                                />
                                {/* @ts-ignore */}
                                {errors?.product_detail?.[i].warehouse_id && (
                                  <div className="mt-2 font-[300] italic text-[0.7rem] text-red-600">
                                    {
                                      errors?.product_detail?.[i]?.warehouse_id
                                        ?.message
                                    }
                                  </div>
                                )}
                              </td>
                              {/* <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.unit_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.unit_name}
                                placeHolder="Unit name"
                              />
                            </td> */}
                              <td>
                                <CustomSelectField
                                  disabled
                                  options={
                                    product_detail?.unit_type_data &&
                                    product_detail?.unit_type_data.map(
                                      (unit: any) => {
                                        return {
                                          label: unit.unit_name,
                                          value: unit.unit_id,
                                        };
                                      }
                                    )
                                  }
                                  classNames="w-[100%] min-w-[300px]"
                                  noLabel
                                  errors={errors}
                                  name={`product_detail.${i}.unit_id`}
                                  //@ts-ignore
                                  control={control}
                                  value={product_detail?.unit_id}
                                  defaultValue={product_detail?.unit_id}
                                />
                              </td>
                              <td>
                                <CustomInputField
                                  readOnly
                                  disabled
                                  noLabel
                                  classNames="w-[100%]"
                                  errors={errors}
                                  name={`product_detail.${i}.sales_price`}
                                  register={register}
                                  setValue={setValue}
                                  value={product_detail.sales_price}
                                />
                              </td>
                              <td>
                                <CustomInputField
                                  max={product_detail.available_qty}
                                  inputType="number"
                                  noLabel
                                  classNames="w-[100%]"
                                  errors={errors}
                                  name={`product_detail.${i}.qty`}
                                  register={register}
                                  setValue={setValue}
                                  value={product_detail.qty}
                                  disabled
                                />
                              </td>
                              <td className="px-2">
                                <div
                                  className={`px-8 py-3 text-primary-dark border-[0.7px] w-[100px] focus:ring-1 
                                                               bg-zinc-100 cursor-not-allowed
                                                                    ring-primary focus:outline-none rounded border-primary-light
                                                            `}
                                >
                                  {product_detail.available_qty}
                                </div>
                                {/* <CustomInputField
                                                            disabled
                                                            inputType="number"
                                                            noLabel
                                                            classNames="w-[100%]"
                                                            errors={errors}
                                                            name={`product_detail.${i}.available_qty`}
                                                            register={register}
                                                        /> */}
                              </td>
                              <td>
                                <CustomInputField
                                  disabled
                                  inputType="number"
                                  noLabel
                                  classNames="w-[100%]"
                                  errors={errors}
                                  name={`product_detail.${i}.amount`}
                                  register={register}
                                  setValue={setValue}
                                  value={product_detail.amount}
                                />
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <CustomInputField
                                    setValue={setValue}
                                    inputType="number"
                                    classNames="-mr-3 w-[130px]"
                                    noLabel
                                    errors={errors}
                                    name={`product_detail.${i}.discount`}
                                    disabled
                                    register={register}
                                    defaultValue={product_detail.discount}
                                    value={product_detail.discount}
                                    min={0}
                                  />
                                  <select
                                    value={
                                      product_detail.discount_type || "AMT"
                                    }
                                    onChange={(e) =>
                                      setValue(
                                        `product_detail.${i}.discount_type`,
                                        e.target.value
                                      )
                                    }
                                    disabled
                                    className={`px-2 mx-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                  >
                                    <option value="AMT">AMT</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <CustomInputField
                                    disabled
                                    setValue={setValue}
                                    inputType="number"
                                    classNames="-mr-3 w-[130px]"
                                    noLabel
                                    errors={errors}
                                    name={`product_detail.${i}.tax`}
                                    register={register}
                                    defaultValue={product_detail.tax}
                                    value={product_detail.tax}
                                    min={0}
                                  />
                                  <select
                                    disabled
                                    value={product_detail.tax_type || "AMT"}
                                    onChange={(e) =>
                                      setValue(
                                        `product_detail.${i}.tax_type`,
                                        e.target.value
                                      )
                                    }
                                    className={`px-2 ml-2 mr-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                  >
                                    <option value="AMT">AMT</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </td>
                              <td>
                                <CustomInputField
                                  inputType="number"
                                  setValue={setValue}
                                  noLabel
                                  errors={errors}
                                  name={`product_detail.${i}.total_amount`}
                                  register={register}
                                  value={product_detail?.total_amount}
                                  disabled
                                />
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <DeleteButton
                                    disabled={true}
                                    id={i}
                                    actionHandler={() => handleRemove(i)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="w-[48%] 2xl:w-[36%] mt-8 float-left">
                  {PromotionData && PromotionData.length > 0 && (
                    <div className="w-[600px] mt-6">
                      <div>
                        <div className="w-[200px] ">
                          <CustomInputField
                            inputType="text"
                            setValue={setValue}
                            errors={errors}
                            label="Promotion Name"
                            name="promotion_name"
                            register={register}
                            value={defaultValues?.promotion_name}
                            disabled
                            required
                          />
                          {/* <CustomSelectField
                          disabled
                          errors={errors}
                          label="Promotion Name"
                          name="sale_promotion_id"
                          placeHolder="Select Promotion Name"
                          //@ts-ignore
                          control={control}
                          options={PromotionData?.map((sp: any) => ({
                            label: sp.promotion_name,
                            value: sp.sale_promotion_id,
                          }))}
                          required
                        /> */}
                        </div>
                      </div>
                    </div>
                  )}

                  {promotionProductData &&
                    formValues?.product_detail?.length > 0 && (
                      <PromotionCard
                        promotionProductData={promotionProductData}
                        bouns={formValues?.bouns}
                        cash_back={formValues?.cash_back}
                      />
                    )}
                </div>
                {formValues?.product_detail?.length > 0 && (
                  <div className="bg-zinc-100 opacity-[0.8] rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                    <div className="flex items-center mb-4">
                      <h5 className="text-[1.05rem] basis-3/12">Sub Total</h5>
                      <span className="s basis-1/12">:</span>
                      <div className="flex items-center basis-6/12"></div>
                      <h5 className="text-right basis-2/12 text-[1.05rem]">
                        {thousandSeperator(formValues.sub_total || 0)}
                      </h5>
                    </div>
                    <div className="flex items-center mb-2">
                      <h5 className=" basis-3/12 text-[1.05rem]">Discount</h5>
                      <span className=" basis-1/12">:</span>
                      <div className="flex items-center basis-6/12">
                        <CustomInputField
                          disabled
                          classNames="-mr-3 w-[130px]"
                          noLabel
                          inputType="number"
                          errors={errors}
                          name={"discount"}
                          placeHolder=" "
                          register={register}
                          defaultValue={
                            secondarySalesOrderData?.discount_type !== "AMT"
                              ? calculatePercentage(
                                  secondarySalesOrderData?.sub_total || 0,
                                  secondarySalesOrderData?.discount_amount || 0
                                )
                              : secondarySalesOrderData?.discount_amount
                          }
                        />
                        <select
                          disabled
                          value={formValues?.discount_type}
                          {...register("discount_type")}
                          className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                        >
                          <option selected value="AMT">
                            AMT
                          </option>
                          <option value="%">%</option>
                        </select>
                      </div>
                      <h5 className="text-right basis-2/12 text-[1.05rem]">
                        -{thousandSeperator(formValues.discount_amount || 0)}
                      </h5>
                    </div>
                    <div className="flex items-center mb-2">
                      <h5 className=" basis-3/12 text-[1.05rem]">Tax</h5>
                      <span className=" basis-1/12">:</span>
                      <div className="flex items-center basis-6/12">
                        <CustomInputField
                          disabled
                          defaultValue={
                            secondarySalesOrderData.tax_type !== "AMT"
                              ? calculatePercentage(
                                  secondarySalesOrderData?.sub_total || 0,
                                  secondarySalesOrderData?.tax_amount || 0
                                )
                              : secondarySalesOrderData?.tax_amount
                          }
                          classNames="-mr-3 w-[130px]"
                          noLabel
                          inputType="number"
                          errors={errors}
                          name={"tax"}
                          placeHolder=" "
                          register={register}
                        />
                        <select
                          disabled
                          value={formValues?.tax_type}
                          {...register("tax_type")}
                          className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                        >
                          <option selected value="AMT">
                            AMT
                          </option>
                          <option value="%">%</option>
                        </select>
                      </div>
                      <h5 className="text-right basis-2/12 text-[1.05rem]">
                        {thousandSeperator(formValues.tax_amount || 0)}
                      </h5>
                    </div>
                    <div className="flex items-center mb-4">
                      <h5 className=" basis-3/12 text-[1.05rem]">
                        Other Charges
                      </h5>
                      <span className=" basis-1/12">:</span>
                      <div className="flex items-center basis-6/12">
                        <CustomInputField
                          disabled
                          defaultValue={0}
                          classNames="-mr-3 w-[200px]"
                          noLabel
                          inputType="number"
                          errors={errors}
                          name={"other_charges"}
                          placeHolder=" "
                          register={register}
                        />
                      </div>
                      <h5 className="text-right basis-2/12 text-[1.05rem]">
                        {thousandSeperator(formValues.other_charges || 0)}
                      </h5>
                    </div>
                    <div className="flex items-center mb-2">
                      <h5 className="text-xl font-semibold basis-3/12">
                        Grand Total
                      </h5>
                      <span className=" basis-1/12">:</span>
                      <div className="flex items-center basis-6/12"></div>
                      <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                        {thousandSeperator(formValues.grand_total_amount || 0)}
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              <CustomInputField
                disabled
                errors={errors}
                name="credit_limit"
                label="Credit Limit"
                register={register}
                //@ts-ignore
                defaultValue={thousandSeperator(formValues?.credit_limit || 0)}
              />
              <CustomInputField
                disabled
                errors={errors}
                placeHolder={thousandSeperator(formValues?.credit_balance || 0)}
                name="credit_balance"
                label="Credit Balance"
                register={register}
                defaultValue={formValues?.credit_balance || 0}
              />
              <CustomInputField
                disabled
                errors={errors}
                name="current_amount"
                label="Current Amount"
                register={register}
                defaultValue={thousandSeperator(
                  formValues?.current_amount || 0
                )}
              />
              <CustomSelectField
                //@ts-ignore
                control={control}
                errors={errors}
                name="status"
                label="Status"
                options={[
                  {
                    label: "Approve & Open",
                    value: 3,
                  },
                  {
                    label: "Reject",
                    value: 4,
                  },
                ]}
                value={formValues.status || 3}
              />
              <CustomInputField
                register={register}
                name="reason"
                errors={errors}
                colSpan={4}
                value={formValues.reason || ""}
              />
            </div>
            <div className="ml-2 mt-14">
              <CustomButton
                isLarge
                title="Cancel"
                color="white"
                textColor="gray.800"
                className="mr-10"
                handleClick={() => navigate(-1)}
              />
              <CustomButton
                handleClick={handleSubmit(onSubmit)}
                isLarge
                title={isSubmitting ? "Loading..." : "Submit"}
                disabled={isSubmitting}
                type="submit"
              />
            </div>
            {errors.root && (
              <div className="mt-6 text-red-500">{errors.root.message}</div>
            )}
          </form>
        </PageWrapper>
      </AuthComponent>
    </>
  );
};

export default OrderApprovalEditPage;
