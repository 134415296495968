import PageWrapper from "@components/layouts/PageWrapper";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";

import { z } from "zod";
import BackButton from "@components/buttons/BackButton";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import {
  addFormValues,
  addProductDetail,
  removeProductDetail,
  replaceProductDetail,
  resetFormValues,
  updateProductDetail,
} from "@states/common/common.slice";
import CustomButton from "@components/buttons/CustomButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomSelectField from "@components/form/CustomSelectField";
import {
  consignmentproductDetailColumns,
  consignmentproductDetailColumnNames,
} from "@helpers/columns/consignment/consignment-detail.columns";
import CustomInputField from "@components/form/CustomInputField";
import { thousandSeperator } from "@utils/general/digit-separators";
import { CONSIGNMENT } from "@utils/constants/consignment/consignment.constants";
import TableSkeleton from "@components/layouts/TableSkeleton";
import TableEmpty from "@components/layouts/TableEmpty";

import {
  useGetConsignmentContractCustomerQuery,
  useGetConsignmentContractProductDetailQuery,
  useLazyGetConsignmentContractProductDetailQuery,
  useCreateConsignmentMutation,
  useLazyGetConsignmentContractListByBuQuery,
} from "@states/consignment/consignment.api";
import { consignmentSchema } from "@helpers/validation-schemas/consignment/consignment.scehma";
import { useGetAllPaymentTermsQuery } from "@states/common/common.api";
import { formatDBDate } from "@utils/general/format-db-date";
import { formatUTCDate } from "@utils/general/format-utc-date";
import { useGetAllConsignmentContractsQuery } from "@states/consignment/consignment-contract.api";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";
import AuthComponent from "@components/auth/component";

type FormFields = z.infer<typeof consignmentSchema>;
type PaymentTerm = {
  payment_terms_id: number;
  payment_terms: string;
};
function truncateDecimal(input: any, decimalPlaces: number = 2): number {
  let number = parseFloat(input);
  if (isNaN(number)) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return number;
  }
  return parseFloat(number.toFixed(decimalPlaces));
}
const ConsignmentAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [businessUnits, setBusinessUnits] = useState<any>([]);

  const { formValues } = useSelector((state: RootState) => state.common);

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<FormFields>({
    resolver: zodResolver(consignmentSchema),
  });

  console.log("erros", errors["customer_id"]);

  const { data: consignmentContractData } = useGetAllConsignmentContractsQuery({
    limit: 1000,
    status: 1,
  });

  const { data: customerData } = useGetConsignmentContractCustomerQuery({
    page: 1,
    limit: 1000,
  });
  const { data: paymentTermData } = useGetAllPaymentTermsQuery();
  // Assuming you have start_date and consignment_duration in form values
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [
    getConsignmentContractListByBu,
    { data: contractListByBuData, isLoading: contractListByBuDataLoading },
  ] = useLazyGetConsignmentContractListByBuQuery();
  const [createConsignment, { isLoading, isSuccess, error: createError }] =
    useCreateConsignmentMutation();

  const [getProductDetail, { data: productData, isLoading: productLoading }] =
    useLazyGetConsignmentContractProductDetailQuery();

  const productBarcodeRef = useRef(null);

  const { append, remove, update, replace } = useFieldArray({
    control,
    name: "product_detail",
  });

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  console.log(customerData, "customerData");
  const {
    regionData,
    regionError,
    regionRefetch,
    regionFetching,
    regionPaginator,
  } = useGetAllRegions({
    limit: 50,
  });

  useEffect(() => {
    if (formValues?.customer_id && customerData?.data) {
      const customer = customerData?.data.find(
        (c: any) => c.customer_id == formValues?.customer_id
      );

      if (customer?.business_units && customer.business_units.length > 0) {
        setBusinessUnits(customer?.business_units || []);
      }
    }
  }, [formValues?.customer_id, customerData]);

  useEffect(() => {
    setValue("product_detail", []);
    if (
      productData &&
      productData?.data.consignment_contract_details.length > 0
    ) {
      const productDetail = productData?.data?.consignment_contract_details.map(
        (product: any) => ({
          product_id: product.product_id,
          unit_id: product.unit_id,
          unit_type_data: product.unit_type_data,
          sales_price: product.sales_price,
          qty: 0,
          total_amount: 0 * product.sales_price,
          product_code: product.product_code,
          product_name: product.product_name,
          unit_name: product.unit_name,
          amount: 0 * product.sales_price,
          discount: 0,
          discount_amount: 0,
          normal_discount: product.normal_discount,
          normal_discount_type: product.normal_discount_type,
          discount_type: "AMT",
          tax: product.secondary_sales_tax,
          tax_amount: 0,
          tax_type: product.secondary_sales_tax_type,
          warehouse_name: product.warehouse_name,
          contract_qty: product.consignment_contract_qty,
          warehouse_id: product.warehouse_id,
          avaliable_qty: product.consignment_contract_qty,
        })
      );
      // Parse the start_date string to a Date object
      const startDate = new Date(productData?.data.start_date);

      // Add consignment_duration (30 days) to startDate
      const maxSaleDate = new Date(startDate);
      maxSaleDate.setDate(
        startDate.getDate() + productData?.data.consignment_duration
      ); // Add 30 days
      setMinDate(startDate);
      // Set the maxDate state
      setMaxDate(maxSaleDate);
      //@ts-ignore
      replace(productDetail);
      dispatch(replaceProductDetail(productDetail));
    } else {
    }
  }, [productData]);
  useEffect(() => {
    // Auto-set the default payment type as "Consignment" (value: 2)
    setValue("payment_type_id", 2);
    // let data = paymentTermData?.find(
    //   (item: PaymentTerm) => item.payment_terms == "3"
    // );
    // console.log("data", paymentTermData);
    //@ts-ignore
    setValue("payment_term_id", 9);
  }, [setValue, paymentTermData]);

  useEffect(() => {
    if (formValues.consignment_contract_id) {
      setValue("consignment_contract_id", formValues.consignment_contract_id);
      // setValue("customer_id", customer_id);
      getProductDetail({
        consignment_contract_id: formValues.consignment_contract_id,
      });
    }
  }, [formValues.consignment_contract_id]);
  useEffect(() => {
    if (formValues?.business_unit_id) {
      getConsignmentContractListByBu({
        business_unit_id: formValues?.business_unit_id,
      });
    }
  }, [formValues?.business_unit_id]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { product_detail, ...theRest } = value;

      const targetIndex = Number(name?.split(".")?.[1]);
      const targetProductDetail: any = product_detail![targetIndex!];
      const changeColumn = name?.split(".")?.[2];
      if (targetProductDetail && product_detail) {
        if (targetProductDetail) {
          // if (changeColumn == "unit_id") {
          //   //@ts-ignore
          //   const unitTypeDataArray = targetProductDetail?.unit_type_data;
          //   // console.log("unitTypeDataArray", unitTypeDataArray);
          //   console.log("changeColumn", changeColumn);
          //   console.log("targetProductDetail", targetProductDetail);
          //   const selectedUnitData = unitTypeDataArray.find(
          //     //@ts-ignore
          //     (data: any) => data.unit_id == targetProductDetail.unit_id // Assumes `selectedOption.value` contains the unit_id
          //   );
          //   if (selectedUnitData.base_qty != 0) {
          //     targetProductDetail.avaliable_qty = Math.floor(
          //       targetProductDetail.contract_qty / selectedUnitData.base_qty
          //     );
          //   }

          //   // targetProductDetail.contract_qty % targetProductDetail;
          // }
          let qty = targetProductDetail.qty;

          if (qty > targetProductDetail.contract_qty) {
            qty = targetProductDetail.contract_qty;
          }
          let tax = isNaN(targetProductDetail.tax)
            ? 0
            : Number(targetProductDetail.tax);
          let discount = isNaN(targetProductDetail.discount)
            ? 0
            : Number(targetProductDetail.discount);
          const updatedProductAmount = targetProductDetail.sales_price * qty;

          const updatedProductDiscount =
            targetProductDetail.discount_type === "%"
              ? updatedProductAmount * (discount / 100)
              : discount;

          const updatedProductTax =
            targetProductDetail.tax_type === "%"
              ? updatedProductAmount * (tax / 100)
              : tax;

          const updatedTotalAmount =
            updatedProductAmount + updatedProductTax - updatedProductDiscount;

          update(targetIndex, {
            ...targetProductDetail,
            discount_amount: truncateDecimal(updatedProductDiscount),
            tax_amount: truncateDecimal(updatedProductTax),
            // amount
            amount: truncateDecimal(updatedProductAmount),
            // total amount
            total_amount: truncateDecimal(updatedTotalAmount),
          });
          // TO DO: check whether this is actaully necessary
          updateProductDetail({
            index: targetIndex,
            product_detail: {
              ...targetProductDetail,
              discount_amount: truncateDecimal(updatedProductDiscount),
              tax_amount: truncateDecimal(updatedProductTax),
              amount: truncateDecimal(updatedProductAmount),
              total_amount: truncateDecimal(updatedTotalAmount),
            },
          });
        }
      }

      // Calculate sub_total
      const subTotal =
        product_detail?.reduce((acc, cur) => {
          if (cur) {
            return acc + (cur.total_amount || 0);
          }
          return acc;
        }, 0) || 0;

      // Calculate discount_amount based on sub_total
      const discountAmount =
        theRest.discount_type === "%"
          ? subTotal * (theRest.discount! / 100)
          : theRest.discount || 0;

      // Calculate discount_amount based on sub_total
      const taxAmount =
        theRest.tax_type === "%"
          ? subTotal * (theRest.tax! / 100)
          : theRest.tax || 0;

      const otherCharges = theRest.other_charges || 0;

      const grandTotalAmount = truncateDecimal(
        subTotal + otherCharges + taxAmount - discountAmount
      );

      //@ts-ignore
      dispatch(
        addFormValues({
          ...theRest,
          // @ts-ignore
          product_detail: product_detail?.map((pd) => ({ ...pd })),
          sub_total: subTotal,
          discount_amount: truncateDecimal(discountAmount),
          discount: theRest.discount || 0,
          tax_amount: truncateDecimal(taxAmount),
          tax: theRest.tax || 0,
          other_charges: otherCharges,
          grand_total_amount: grandTotalAmount,
          //@ts-ignore
          // sales_date: new Date(theRest.sales_date).toISOString(),
        })
      );
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);

  const handleRemove = (index: number) => {
    remove(index);
    dispatch(removeProductDetail(index));
  };
  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      console.log(data);

      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { product_detail, discount, tax, ...rest } = formValues;

        const formattedProductDetails = product_detail?.map((pd: any) => ({
          product_id: pd.product_id,
          unit_id: pd.unit_id,
          // unit_name: pd.unit_name,
          sales_price: pd.sales_price,
          qty: pd.qty,
          amount: pd.amount,
          normal_discount_type: pd.normal_discount_type,
          normal_discount: pd.normal_discount,
          discount_type: pd.discount_type,
          discount_amount: pd.discount_amount,
          tax_type: pd.tax_type,
          tax_amount: pd.tax_amount,
          total_amount: pd.total_amount,
          warehouse_id: pd.warehouse_id,
          consignment_contract_qty: pd.contract_qty,
          discount: pd.discount,
          tax: pd.tax,
        }));

        console.log("Form", {
          ...rest,
          tax: formValues.tax,
          discount: formValues.discount,
          products: formattedProductDetails,
        });

        const res = await createConsignment({
          tax: formValues.tax,
          discount: formValues.discount,
          ...rest,
          sales_date: formatDBDate(rest?.sales_date),
          products: formattedProductDetails,
        });
        console.log("Result", res);
        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;
        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });
          navigate(CONSIGNMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [createConsignment, isSuccess, Toast, navigate, formValues]
  );

  //@ts-ignore
  console.log("fromValue", formValues?.business_unit_id);
  return (
    <AuthComponent
      action="create"
      module="Consignment"
      subModule="Consignment"
      page
    >
      <PageWrapper>
        {(isLoading || productLoading) && <LoadingSpinner />}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            Add New Consignment
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomSearchModalChooseField
              key={"customer_id"}
              errors={errors}
              colSpan={2}
              name={"customer_id"}
              label="Customer Name"
              title={"Customer List"}
              columns={[
                {
                  name: "customer_name",
                  columnName: "Customer Name",
                },
                {
                  name: "phone_number",
                  columnName: "Phone Number",
                },
              ]}
              // @ts-ignore
              control={control}
              register={register}
              // borderColor={field.borderColor}
              placeHolder="Choose customer name"
              setValue={setValue}
              // @ts-ignore
              data={customerData?.data.map((customer) => ({
                customer_id: customer.customer_id,
                customer_name:
                  customer.customer_first_name +
                  " " +
                  (customer.customer_last_name || ""),
                phone_number: customer.customer_phone1,
              }))}
              columnName={"Customer Name"}
              idName={"customer_id"}
              // value={field.value}
              nameName={"customer_name"}
              required
            />
            <CustomSelectField
              disabled={getValues("customer_id") === undefined}
              errors={errors}
              name="business_unit_id"
              label="Business Unit"
              placeHolder="Select business unit"
              //@ts-ignore
              control={control}
              options={businessUnits?.map((bu: any) => ({
                label: bu.business_unit_name,
                value: bu.business_unit_id,
              }))}
              required
            />
            <CustomSelectField
              errors={errors}
              name="consignment_contract_id"
              label="Consignment Contract"
              placeHolder="Select Consignment Contract"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={contractListByBuData?.data?.map((item: any) => ({
                label: item.consignment_contract_code,
                value: item.consignment_contract_id,
              }))}
              required
            />

            <CustomDateField
              errors={errors}
              name="sales_date"
              label="Sale Date"
              register={register}
              key="sales_date"
              //@ts-ignore
              control={control}
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              disabled={!formValues?.consignment_contract_id}
              required
            />

            <CustomSelectField
              errors={errors}
              name="region_id"
              label="Distribution Region"
              placeHolder="Select Distribution Region"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={regionData?.map((item: any) => ({
                label: item.region_name,
                value: item.region_id,
              }))}
              required
            />
            <CustomSelectField
              errors={errors}
              name="payment_type_id"
              label="Payment Type"
              placeHolder="Select payment type"
              //@ts-ignore
              control={control}
              options={[
                // {
                //     label: 'Credit',
                //     value: 1,
                // },
                { label: "Consignment", value: 2 },
                // { label: 'Cashdown', value: 3 },
              ]}
              required
            />
            <CustomSelectField
              errors={errors}
              name="payment_term_id"
              label="Payment Term"
              placeHolder="Select payment term"
              //@ts-ignore
              control={control}
              options={paymentTermData
                ?.filter((item: any) => item.payment_terms == 3)
                .map((item: any) => ({
                  label: item.payment_terms,
                  value: item.payment_terms_id,
                }))}
              //@ts-ignore
              required
            />
            <CustomTextAreaField
              key={"description"}
              errors={errors}
              colSpan={6}
              name="description"
              register={register}
            />
            <div className="flex justify-between col-span-6">
              <h3 className="ml-2 font-[600] text-primary-dark">
                <span className=" text-[1.1rem]">Add Consignment Detail</span>
              </h3>
            </div>
            <div className="flex flex-col justify-between col-span-6">
              <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                <span className=" text-[1.3rem]">Product Detail</span>
              </h3>
            </div>
            <div className="-mt-4 col-span-full">
              <DataTable
                className="pb-4"
                responsive
                striped
                //@ts-ignore
                columns={consignmentproductDetailColumns(
                  errors,
                  register,
                  setValue,
                  handleRemove,
                  control
                )}
                //@ts-ignore
                data={formValues?.product_detail?.map((f, i) => ({
                  ...f,
                  index: i,
                }))}
                noDataComponent={
                  <TableEmpty
                    columnNames={consignmentproductDetailColumnNames}
                  />
                }
              />

              {formValues?.product_detail?.length > 0 && (
                <div className="bg-zinc-100 rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                  <div className="flex items-center mb-4">
                    <h5 className="text-[1.05rem] basis-3/12">Sub Total</h5>
                    <span className="s basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.sub_total || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className=" basis-3/12 text-[1.05rem]">Discount</h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        defaultValue={0}
                        classNames="-mr-3 w-[130px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"discount"}
                        placeHolder=" "
                        register={register}
                      />
                      <select
                        {...register("discount_type")}
                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      >
                        <option selected value="AMT">
                          AMT
                        </option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      -{thousandSeperator(formValues.discount_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className=" basis-3/12 text-[1.05rem]">Tax</h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        defaultValue={0}
                        classNames="-mr-3 w-[130px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"tax"}
                        placeHolder=" "
                        register={register}
                      />
                      <select
                        {...register("tax_type")}
                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      >
                        <option selected value="AMT">
                          AMT
                        </option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.tax_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-4">
                    <h5 className=" basis-3/12 text-[1.05rem]">
                      Other Charges
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        defaultValue={0}
                        classNames="-mr-3 w-[200px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"other_charges"}
                        placeHolder=" "
                        register={register}
                      />
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.other_charges || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className="text-xl font-semibold basis-3/12">
                      Grand Total
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                      {thousandSeperator(formValues.grand_total_amount || 0)}
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isSubmitting ? "Loading..." : "Submit"}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
          {errors.root && (
            <div className="mt-6 text-red-500">{errors.root.message}</div>
          )}
        </form>
      </PageWrapper>
    </AuthComponent>
  );
};

export default ConsignmentAddPage;
